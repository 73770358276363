import axiosInstance, { microServices } from "network/apis";
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from "utils/constants";

const LIST = (competitionId) => `/v1/competitions/${competitionId}/events`;
const GET_ONE = (competitionId, eventId) =>
  `/v1/competitions/${competitionId}/events/${eventId}`;
const CREATE = (competitionId) => `/v1/competitions/${competitionId}/events`;
const UPDATE = (competitionId, eventId) =>
  `/v1/competitions/${competitionId}/events/${eventId}`;
const DELETE = (competitionId, eventId) =>
  `/v1/competitions/${competitionId}/events/${eventId}`;

export const listEventsService = (payload) => {
  const { competitionId, data } = payload;
  return axiosInstance(METHOD_TYPES.get, LIST(competitionId), data, {
    server: microServices.BASE_URL1,
    handlerEnabled: true,
    loader: LOADER_HANDLER_TYPES.page,
  });
};

export const getEventService = (payload) => {
  const { competitionId, eventId } = payload;

  return axiosInstance(
    METHOD_TYPES.get,
    GET_ONE(competitionId, eventId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.submit,
    }
  );
};

export const createEventService = (payload) => {
  const { competitionId, data } = payload;
  return axiosInstance(METHOD_TYPES.post, CREATE(competitionId), data, {
    server: microServices.BASE_URL1,
    handlerEnabled: true,
    loader: LOADER_HANDLER_TYPES.page,
    successMessage: "تم الإضافة بنجاح",
  });
};

export const updateEventService = (payload) => {
  const { competitionId, eventId, data } = payload;

  return axiosInstance(METHOD_TYPES.put, UPDATE(competitionId, eventId), data, {
    server: microServices.BASE_URL1,
    handlerEnabled: true,
    loader: LOADER_HANDLER_TYPES.page,
    successMessage: "تم التعديل بنجاح",
  });
};
export const deleteEventService = (payload) => {
  const { competitionId, eventId } = payload;

  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE(competitionId, eventId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: "تم الحذف بنجاح",
    }
  );
};
