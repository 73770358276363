import { useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'

export default function Logo({
    height = '95px',
    width = 'auto',
    p = 1,
    ...rest
}) {
    const theme = useTheme();
    const matchesLgScreen = useMediaQuery(theme.breakpoints.up('md'));


    return (
        <Box
            sx={{ p }}
            style={{ height, width, maxWidth: '100%' }}
        >
            <img
                src={matchesLgScreen ? require('assets/media/images/abegs-vertical.svg').default : require('assets/media/images/abegs.svg').default}
                alt="Logo"
                style={{ height: '100%' }}
            />
        </Box>
    )
}
