import { createAsyncThunk } from '@reduxjs/toolkit'
import { createStageService, deleteStageService, getStageService, listStagesService, updateStageService } from 'network/services/stage.service'
import { ROUTE_PATHS } from 'utils/constants'


export const operatorListStages = createAsyncThunk('stage/list', async (data, thunkApi) => {
  try {
    return await listStagesService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorGetStage = createAsyncThunk('stage/get', async (data, thunkApi) => {
  try {
    return await getStageService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateStage = createAsyncThunk('stage/create', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await createStageService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.stageParentRoute}`)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateStage = createAsyncThunk('stage/update', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await updateStageService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.stageParentRoute}`)

    return res

  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorDeleteStage = createAsyncThunk('stage/delete', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await deleteStageService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})
