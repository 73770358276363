import { createSlice } from '@reduxjs/toolkit'
import { getLocalLanguage } from 'utils/common-services/localstorage.services'
import { LANGUAGE, LANGUAGES } from 'utils/constants'

const i18nState = {
  [LANGUAGE]: getLocalLanguage() || LANGUAGES[0].languageCode,
}

const i18nSlice = createSlice({
  name: 'i18nSlice',
  initialState: i18nState,
  reducers: {
    updateLanguage: (state, { payload }) => {
      return {
        ...state,
        [LANGUAGE]: payload
      }
    },
  }
})

export const { updateLanguage } = i18nSlice.actions

export default i18nSlice.reducer
