import { createSlice } from '@reduxjs/toolkit'
import { operatorCreateComment, operatorCreateInquiry, operatorDeleteComment, operatorDeleteInquiry, operatorGetInquiry, operatorListInquiries, operatorUpdateComment, operatorUpdateInquiry } from 'redux-thunk/thunk/Inquiry/Inquiry'

const defaultState = {
  page: 1,
  createdAt: 'descending',
  currentInquiry: {}
}

const inquirySlice = createSlice({
  name: 'inquirySlice',
  initialState: defaultState,
  reducers: {
    changeInquiriesFilters: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(operatorListInquiries.fulfilled, (state, action) => {
        return {
          ...defaultState,
          ...action.payload
        }
      })
      .addCase(operatorGetInquiry.fulfilled, (state, action) => {
        return {
          ...state,
          currentInquiry: action.payload
        }
      })
      .addCase(operatorCreateInquiry.fulfilled, (state, action) => {
        state.docs.push(action.payload)
      })
      .addCase(operatorUpdateInquiry.fulfilled, (state, action) => {
        const index = state.docs.findIndex((element, index) => {
          if (element.id === action.meta.arg.inquiryId) {
            return true
          }
          return false
        })

        state.docs[index] = action.payload
      })
      .addCase(operatorDeleteInquiry.fulfilled, (state, action) => {
        state.docs = state.docs.filter((doc) => doc.id !== action.meta.arg.inquiryId)
      })
      .addCase(operatorCreateComment.fulfilled, (state, action) => {
        return {
          ...defaultState,
          currentInquiry: action.payload
        }
      })
      .addCase(operatorUpdateComment.fulfilled, (state, action) => {
        return {
          ...defaultState,
          currentInquiry: action.payload
        }
      })
      .addCase(operatorDeleteComment.fulfilled, (state, action) => {
        return {
          ...defaultState,
          currentInquiry: action.payload
        }
      })
  }
})
export const { changeInquiriesFilters } = inquirySlice.actions

export default inquirySlice.reducer
