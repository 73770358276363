import { createAsyncThunk } from '@reduxjs/toolkit'
import { createResultService, deleteResultService, getResultService, listResultsService, updateResultService } from 'network/services/result.service'
import { ROUTE_PATHS } from 'utils/constants'


export const operatorListResults = createAsyncThunk('result/list', async (data, thunkApi) => {
  try {
    return await listResultsService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorGetResult = createAsyncThunk('result/get', async (data, thunkApi) => {
  try {
    return await getResultService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateResult = createAsyncThunk('result/create', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await createResultService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.resultParentRoute}`)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateResult = createAsyncThunk('result/update', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await updateResultService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.resultParentRoute}`)

    return res

  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorDeleteResult = createAsyncThunk('result/delete', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await deleteResultService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})
