import { createSlice } from '@reduxjs/toolkit'
import { operatorCreateResult, operatorDeleteResult, operatorGetResult, operatorListResults, operatorUpdateResult } from 'redux-thunk/thunk/Result/Result'

const defaultState = {}

const resultSlice = createSlice({
  name: 'resultSlice',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(operatorListResults.fulfilled, (state, action) => {
        return {
          ...defaultState,
          results: action.payload
        }
      })
      .addCase(operatorGetResult.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorCreateResult.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorUpdateResult.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorDeleteResult.fulfilled, (state, action) => {
        state.results = state.results.filter((doc) => doc.id !== action.meta.arg.resultId)
      })
  }
})

export default resultSlice.reducer
