import { createSlice } from '@reduxjs/toolkit'
import { operatorAddAttachment, operatorChangeSubmissionStatus, operatorCreateSubmission, operatorDeleteAttachment, operatorDeleteSubmission, operatorGetMySubmission, operatorListSubmissions } from 'redux-thunk/thunk/Submission/Submission'

const defaultState = {
  page: 1,
  createdAt: 'descending',
}

const submissionSlice = createSlice({
  name: 'submissionSlice',
  initialState: defaultState,
  reducers: {
    changeSubmissionsFilters: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(operatorListSubmissions.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload
        }
      })
      .addCase(operatorDeleteSubmission.fulfilled, (state, action) => {
        state.docs = state.docs.filter((doc) => doc.id !== action.meta.arg.submissionId)
      })
      .addCase(operatorCreateSubmission.fulfilled, (state, action) => {
        return {
          ...defaultState
        }
      })
      .addCase(operatorGetMySubmission.fulfilled, (state, action) => {
        return {
          ...state,
          mySubmission: action.payload
        }
      })
      .addCase(operatorDeleteAttachment.fulfilled, (state, action) => {
        state.mySubmission.attachments = state.mySubmission.attachments.filter((file) => file.id !== action.meta.arg.attachmentId)
      })
      .addCase(operatorAddAttachment.fulfilled, (state, action) => {
        state.mySubmission.attachments = action.payload.attachments
      })
      .addCase(operatorChangeSubmissionStatus.fulfilled, (state, action) => {
        const doc = state.docs.find((doc) => doc.id === action.meta.arg.submissionId)
        if (doc) {
          doc.status = 'accepted'
        }
      })
  }
})

export const { changeSubmissionsFilters } = submissionSlice.actions

export default submissionSlice.reducer
