import { createSlice } from '@reduxjs/toolkit'
import { operatorCreateEvent, operatorDeleteEvent, operatorGetEvent, operatorListEvents, operatorUpdateEvent } from 'redux-thunk/thunk/Event/Event'

const defaultState = {}

const eventSlice = createSlice({
  name: 'eventSlice',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(operatorListEvents.fulfilled, (state, action) => {
        return {
          ...defaultState,
          events: action.payload
        }
      })
      .addCase(operatorGetEvent.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorCreateEvent.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorUpdateEvent.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorDeleteEvent.fulfilled, (state, action) => {
        state.events = state.events.filter((doc) => doc.id !== action.meta.arg.eventId)
      })
  }
})

export default eventSlice.reducer
