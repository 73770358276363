import { createSlice } from '@reduxjs/toolkit'

const defaultState = {}

const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState: defaultState,
  reducers: {  }
})

export const {} = dashboardSlice.actions

export default dashboardSlice.reducer
