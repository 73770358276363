import axiosInstance, { microServices, microServicesURLs } from 'network/apis'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'

const LOGIN = 'v1/auth/login'
const SOCIAL_LOGIN = `v1/auth/google/login`
const REGISTER = '/v1/auth/register'
const PROFILE = '/v1/auth/profile'
const FORGET_PASSWORD = '/v1/auth/forget-password'
const RESET_PASSWORD = '/v1/auth/reset-password'
const LOGOUT = 'auth/logout'
const UPDATEPASSWORD = 'profile/change-password'

/**
 * Service to do login
 * @param {object} data - object contains username and password
 */
export const loginService = (data) => {
  return axiosInstance(
    METHOD_TYPES.post,
    LOGIN,
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تسجيل الدخول بنجاح'
    }
  )
}

export const socialLoginService = () => {
  window.location.href = `${microServicesURLs.BASE_URL1}/${SOCIAL_LOGIN}`;
}

/**
 * Service to do REGISTER
 * @param {object} data - object contains username and password
 */
export const registerService = (data) => {
  return axiosInstance(
    METHOD_TYPES.post,
    REGISTER,
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: false,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تسجيل الحساب بنجاح، الرجاء تفحص البريد الالكتروني الخاص بك لتفعيل الحساب'
    }
  )
}
/**
 * Service to do Reset password
 * @param {object} data - object contains username and password
 */
export const forgetPasswordService = (data) => {
  return axiosInstance(
    METHOD_TYPES.post,
    FORGET_PASSWORD,
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: false,
      loader: LOADER_HANDLER_TYPES.page,
    }
  )
}
/**
 * Service to do Reset password
 * @param {object} data - object contains username and password
 */
export const resetPasswordService = (data) => {
  return axiosInstance(
    METHOD_TYPES.post,
    RESET_PASSWORD,
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: false,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تغيير كلمة المرور بنجاح، الرجاء اعادة تسجيل الدخول'
    }
  )
}

/**
 * Service to do logout
 */
export const logoutService = () => {
  return axiosInstance(
    METHOD_TYPES.get,
    LOGOUT,
    {},
    {
      server: microServices.BASE_URL1,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تسجيل الخروج بنجاح'
    }
  )
}

/**
 * Service to change password
 * @param {object} data - object contains old and new password
 */
export const updatePasswordService = (data) => {
  return axiosInstance(
    METHOD_TYPES.put,
    UPDATEPASSWORD,
    data,
    {
      server: microServices.BASE_URL1,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تغيير كلمة المرور بنجاح'
    }
  )
}

/**
 * Service to get profile
 * @param {string} data - token string
 */
export const profileService = (data) => {
  return axiosInstance(
    METHOD_TYPES.get,
    PROFILE,
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.submit,
    }
  )
}

/**
 * Service to update profile
 * @param {string} data - token string
 */
export const updateProfileService = (data) => {
  return axiosInstance(
    METHOD_TYPES.put,
    PROFILE,
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تعديل البيانات بنجاح'
    }
  )
}
