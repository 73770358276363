import { EnglishIcon } from "components/ui-kit/icons/iconComponents/EnglishIcon";
import { SaudiArabicIcon } from "components/ui-kit/icons/iconComponents/SaudiArabicIcon";

/* ==========================================================================
  LANGUAGES
  ========================================================================== */
const LANGUAGES = [
  { label: "العربية", languageCode: "ar", flag: SaudiArabicIcon, isRTL: true },
  { label: "English", languageCode: "en", flag: EnglishIcon, isRTL: false },
];

/* ==========================================================================
  HTTP Method Types
========================================================================== */
const METHOD_TYPES = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
  patch: "patch",
};

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
const TOKEN = "authtoken";
const LANGUAGE = "language";

/* ==========================================================================
Loader types
========================================================================== */
const LOADER_TYPE = {
  SCALE: "scale",
  PULSE: "pulse",
};

/* ==========================================================================
  TOASTER / NOTIFICATION
========================================================================== */
const TOASTER_TYPE = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};

/* ==========================================================================
  Loader Type Array
========================================================================== */
const LOADER_HANDLER_TYPES = {
  page: "pageLoader",
  submit: "submitButtonLoader",
  table: "tableLoader",
  data: "dataLoader",
};

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
const usersParentRoute = "users";
const competitionParentRoute = "competitions";
const dashboardParentRoute = "/dashboard";
const categoryParentRoute = "category";
const inquiryParentRoute = "inquiries";
const sponsorParentRoute = "sponsor";
const resultParentRoute = "result";
const eventParentRoute = "event";
const groupParentRoute = "group";
const stageParentRoute = "stage";
const entityParentRoute = "entity";
const submissionParentRoute = "submission";

const ROUTE_PATHS = {
  // ----single routes------------
  rootPath: "/",
  events: "/events",
  results: "/results",
  terms: "/terms",
  applyForCompetition: "/apply",
  wizard: "/wizard",

  login: "/login",
  register: "/register",
  forgetPassword: "/forget-password",
  resetCode: "/reset-code",
  changePassword: "/change-password",

  competitionParentRoute: competitionParentRoute,
  inquiryParentRoute: inquiryParentRoute,
  categoryParentRoute: categoryParentRoute,
  sponsorParentRoute: sponsorParentRoute,
  resultParentRoute: resultParentRoute,
  eventParentRoute: eventParentRoute,
  groupParentRoute: groupParentRoute,
  stageParentRoute: stageParentRoute,
  entityParentRoute: entityParentRoute,
  submissionParentRoute: submissionParentRoute,

  // ----competition child routes------------
  dashboard: dashboardParentRoute,
  personalInfo: `${dashboardParentRoute}/personal-info`,

  users: `${dashboardParentRoute}/${usersParentRoute}`,
  newUser: `${dashboardParentRoute}/${usersParentRoute}/new`,
  singleUser: `${dashboardParentRoute}/${usersParentRoute}/:userId`,
  updateUser: `${dashboardParentRoute}/${usersParentRoute}/:userId/update`,

  competitions: `${dashboardParentRoute}/${competitionParentRoute}`,
  newCompetition: `${dashboardParentRoute}/${competitionParentRoute}/new`,
  activeCompetition: `${dashboardParentRoute}/${competitionParentRoute}/active`,
  singleCompetition: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId`,
  updateCompetition: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/update`,

  inquiries: `${dashboardParentRoute}/${inquiryParentRoute}`,
  newInquiry: `${dashboardParentRoute}/${inquiryParentRoute}/new`,
  singleInquiry: `${dashboardParentRoute}/:competitionId/${inquiryParentRoute}/:inquiryId`,
  updateInquiry: `${dashboardParentRoute}/:competitionId/${inquiryParentRoute}/:inquiryId/update`,

  participation: `${dashboardParentRoute}/${competitionParentRoute}/participation`,
  updateParticipation: `${dashboardParentRoute}/${competitionParentRoute}/participation/update`,

  category: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${categoryParentRoute}`,
  newCategory: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${categoryParentRoute}/new`,
  singleCategory: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${categoryParentRoute}/:categoryId`,
  updateCategory: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${categoryParentRoute}/:categoryId/update`,

  sponsor: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${sponsorParentRoute}`,
  newSponsor: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${sponsorParentRoute}/new`,
  singleSponsor: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${sponsorParentRoute}/:sponsorId`,
  updateSponsor: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${sponsorParentRoute}/:sponsorId/update`,

  result: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${resultParentRoute}`,
  newResult: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${resultParentRoute}/new`,
  singleResult: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${resultParentRoute}/:resultId`,
  updateResult: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${resultParentRoute}/:resultId/update`,

  event: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${eventParentRoute}`,
  newEvent: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${eventParentRoute}/new`,
  singleEvent: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${eventParentRoute}/:eventId`,
  updateEvent: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${eventParentRoute}/:eventId/update`,

  group: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${groupParentRoute}`,
  newGroup: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${groupParentRoute}/new`,
  singleGroup: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${groupParentRoute}/:groupId`,
  updateGroup: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${groupParentRoute}/:groupId/update`,

  stage: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${stageParentRoute}`,
  newStage: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${stageParentRoute}/new`,
  singleStage: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${stageParentRoute}/:stageId`,
  updateStage: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${stageParentRoute}/:stageId/update`,

  entity: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${entityParentRoute}`,
  newEntity: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${entityParentRoute}/new`,
  singleEntity: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${entityParentRoute}/:entityId`,
  updateEntity: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${entityParentRoute}/:entityId/update`,

  submission: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${submissionParentRoute}`,
  usersSubmission: `${dashboardParentRoute}/${submissionParentRoute}/current`,
  singleSubmission: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${submissionParentRoute}/:submissionId`,

  // Admin messages to Contistant
  messages: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${submissionParentRoute}/:submissionId/messages`,
  newMessage: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${submissionParentRoute}/:submissionId/newmessage`,
  singleMessage: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${submissionParentRoute}/:submissionId/messages/:messageId`,
  updateMessage: `${dashboardParentRoute}/${competitionParentRoute}/:competitionId/${submissionParentRoute}/:submissionId/messages/:messageId/update`,
  // End Of Admin Messages
};

const SIDEBAR = {
  width: "260px",
};

const ACCEPTED_FILES_TYPES =
  ".mp3,.doc,.pdf,audio/*,video/*,image/*,application/msword,media_type";

export {
  ACCEPTED_FILES_TYPES,
  SIDEBAR,
  TOASTER_TYPE,
  LANGUAGES,
  TOKEN,
  LANGUAGE,
  ROUTE_PATHS,
  LOADER_TYPE,
  METHOD_TYPES,
  LOADER_HANDLER_TYPES,
};

export const COUNTRIES = [
  {
    label: "AE",
    value: "AE",
  },
  {
    label: "BH",
    value: "BH",
  },
  {
    label: "YE",
    value: "YE",
  },
  {
    label: "KW",
    value: "KW",
  },
  {
    label: "SA",
    value: "SA",
  },
  {
    label: "OM",
    value: "OM",
  },
  {
    label: "QA",
    value: "QA",
  },
];

export const GENDERS = [
  {
    label: "male",
    value: "male",
  },
  {
    label: "female",
    value: "female",
  },
];

export const ROLES = [
  {
    label: "Contestant",
    value: "contestant",
  },
  {
    label: "Secretary",
    value: "secretary",
  },
  {
    label: "Admin",
    value: "admin",
  },
];

export const USER_ROLES = {
  contestant: "contestant",
  // Judge: "judge",
  secretary: "secretary",
  admin: "admin",
  visitor: "visitor",
  null: null,
};

export const COMPONENT_TYPES = {
  view: "view",
  update: "update",
  create: "create",
};

export const CompetitionStatus = {
  Reception: "reception",
  Review: "review",
  Judgement: "judgement",
  Archival: "archival",
};

export const PORTALS = {
  dashboardTitle: "dashboard-page-title",
  dashboardActions: "dashboard-page-actions",
};

export const CompetitionStatusArr = [
  "reception",
  "review",
  "judgement",
  "archival",
];

export const SUBMISSION_STATUS = {
  submitted: "submitted",
  underReview: "under-review",
  reviewed: "reviewed",
  accepted: "accepted",
  rejected: "rejected",
  underJudgement: "under-judgement",
  judged: "judged",
  won: "won",
};

export const STATUS_MAP = {
  [CompetitionStatus.Reception]: [SUBMISSION_STATUS.submitted],
  [CompetitionStatus.Review]: [
    SUBMISSION_STATUS.underReview,
    SUBMISSION_STATUS.reviewed,
    SUBMISSION_STATUS.accepted,
    SUBMISSION_STATUS.rejected,
  ],
  [CompetitionStatus.Judgement]: [
    SUBMISSION_STATUS.underJudgement,
    SUBMISSION_STATUS.judged,
    SUBMISSION_STATUS.won,
  ],
  [CompetitionStatus.Archival]: [],
};

export const STEPS = [
  {
    title: "المرحلة الأولى",
    description: "الإعلان عن المسابقة واستقبال الاعمال",
    date: "10 مايو - 10 سبتمبر",
  },
  {
    title: "المرحلة الثانية",
    description: "فرز المشاركات",
    date: "10 سبتمبر - 10 أكتوبر",
  },
  {
    title: "المرحلة الثالثة",
    description: "تحكيم الاعمال",
    date: "10 أكتوبر - 10 نوفمبر",
  },
  {
    title: "المرحلة الرابعة",
    description: "إعلان النتائج و حفل التكريم",
    date: "",
  },
];

export const TERMS = [
  "يجب أن يكون المشارك منتجاً ومالكاً لجميع حقوق الملكية المتعلقة بالمواد المنتجة، ولن يتم قبول أي مواد لا يكون المشارك بها منتجاً أو مالكاً لجميع حقوق الملكية المتعلقة بالمواد المنتجة",
  "تقع المسؤولية القانونية الكاملة والناتجة عن أي انتهاك لحقوق الملكية الفكرية للغير على المشارك ولا يتحمل المكتب أي اثار قانونية او مادية او أدبية عن أي اختراقات لقوانين الملكية الفكرية.",
  "مراعاة عدم احتواء الأعمال المتقدم بها على أي مواد إعلانية مهما كان نوعها (سلبا او إيجابا).",
  "أن تكون الاعمال اصيلة ولم يتم المشاركة بها أو الفوز في مسابقات أخرى",
  "على جميع المشاركين الالتزام بمواعيد بدء وانتهاء المدة المحددة لتقديم المشاركات.",
  "يقر المتقدم للمشاركة بموافقته على هذه الشروط وقبول من يختاره المنظمون للقيام بمهام التحكيم والتقييم.",
  "لا يحق للمشترك بأي حال من الأحوال سحب المواد المنتجة من المسابقة في حال إغلاق باب المشاركات.",
  "لا يتحمل المكتب أي أخطاء تقنية أو ظروف طبيعية أو أخطاء غير متعمدة تتسبب بتأخر استلام المواد عن الموعد المحدد لقبول المشاركات .",
  "لا يتحمل المكتب أي مسؤولية لأي تكلفة مالية أو أي نوع من أنواع التكاليف التي قد تنتج عن المشاركة في المسابقة .",
  "يحق لإدارة الداتاثون رفض أي مواد مقدمة، لا تتوافق مع المواصفات أو الأهداف أو المواضيع أو الشروط او لوجود ملاحظات على المواد المنتجة، دون إبداء أي أسباب.",
  "يحتفظ مكتب التربية العربي لدول الخليج بحق إلغاء أو تمديد أو تعديل مجريات الداتاثون أو شروطها أو أحكامها من دون إشعار مسبق، وسيتم نشر أي تغييرات تجري على حسابات مواقع التواصل الاجتماعي والموقع الإلكتروني للمكتب.",
  "لا يحق لموظفي مكتب التربية العربي لدول الخليج أو لجنة تحكيم المسابقة المشاركة في الداتاثون.",
  "يحق للجنة التحكيم ومكتب التربية العربي لدول الخليج حجب جائزة أحد المراكز أو الفائزين في حال لم تحقق الأعمال المقدمة ا خالفة العمل الفائز لشروط لمواصفات المطلوبة أو لم الداتاثون سواءً تم التحقق من ذلك قبل عملية الترشيح أو أثناء التحكيم أو بعد إعلان الفائزين.",
  "يحتفظ مكتب التربية العربي لدول الخليج بحق نشر وتوزيع وإعادة انتاج المواد الفائزة بالداتاثون مع احتفاظ المنتج بحق بقاء اسمه على المنتج.",
  "يتعهد المشارك او المتسابق أو الجهة المشاركة بأنه اطلع على جميع ما تضمنته الداتاثون من معلومات وشروط وأحكام للتقديم عليها، وموافقته التامة والشاملة على ذلك، كذلك يتعهد بعدماعتراضه في أي حال من الأحوال على نتائج الفائزين او اداء وتقييم لجنة التحكيم او اللجنة المنظمة للداتاثون وعدم الاساءة لهم كلياً، وموافقته الكاملة على النتائج وعدم الاعتراض عليها اجمالاً.",
];
