import { createSlice } from '@reduxjs/toolkit'
import { operatorCreateEntity, operatorDeleteEntity, operatorGetEntity, operatorListEntity, operatorUpdateEntity } from 'redux-thunk/thunk/Entity/Entity'

const defaultState = {}

const entitySlice = createSlice({
  name: 'entitySlice',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(operatorListEntity.fulfilled, (state, action) => {
        return {
          ...defaultState,
          entities: action.payload
        }
      })
      .addCase(operatorGetEntity.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorCreateEntity.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorUpdateEntity.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorDeleteEntity.fulfilled, (state, action) => {
        state.entities = state.entities.filter((doc) => doc.id !== action.meta.arg.entityId)
      })
  }
})

export default entitySlice.reducer
