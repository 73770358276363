import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCategoryService,
  deleteCategoryService,
  getCategoryService,
  listCategoriesService,
  updateCategoryService,
} from "network/services/category.service";
import { ROUTE_PATHS } from "utils/constants";

export const operatorListCategories = createAsyncThunk(
  "category/list",
  async (data, thunkApi) => {
    try {
      return await listCategoriesService(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorGetCategory = createAsyncThunk(
  "category/get",
  async (data, thunkApi) => {
    try {
      return await getCategoryService(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorCreateCategory = createAsyncThunk(
  "category/create",
  async ({ navigate, ...payload }, thunkApi) => {
    try {
      const res = await createCategoryService(payload);
      navigate(
        `${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.categoryParentRoute}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorUpdateCategory = createAsyncThunk(
  "category/update",
  async ({ navigate, ...payload }, thunkApi) => {
    try {
      const res = await updateCategoryService(payload);
      navigate(
        `${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.categoryParentRoute}`
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorDeleteCategory = createAsyncThunk(
  "category/delete",
  async ({ navigate, ...payload }, thunkApi) => {
    try {
      const res = await deleteCategoryService(payload);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error.description);
    }
  }
);
