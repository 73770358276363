import { createAsyncThunk } from '@reduxjs/toolkit'
import { createGroupService, deleteGroupService, getGroupService, listGroupsService, updateGroupService } from 'network/services/group.service'
import { ROUTE_PATHS } from 'utils/constants'


export const operatorListGroups = createAsyncThunk('group/list', async (data, thunkApi) => {
  try {
    return await listGroupsService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorGetGroup = createAsyncThunk('group/get', async (data, thunkApi) => {
  try {
    return await getGroupService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateGroup = createAsyncThunk('group/create', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await createGroupService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.groupParentRoute}`)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateGroup = createAsyncThunk('group/update', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await updateGroupService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.groupParentRoute}`)

    return res

  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorDeleteGroup = createAsyncThunk('group/delete', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await deleteGroupService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})
