import axiosInstance, { microServices } from 'network/apis'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'

const LIST = (competitionId) => `/v1/competitions/${competitionId}/results`
const GET_ONE = (competitionId, resultId) => `/v1/competitions/${competitionId}/results/${resultId}`
const CREATE = (competitionId) => `/v1/competitions/${competitionId}/results`
const UPDATE = (competitionId, resultId) => `/v1/competitions/${competitionId}/results/${resultId}`
const DELETE = (competitionId, resultId) => `/v1/competitions/${competitionId}/results/${resultId}`


export const listResultsService = (payload) => {
  const { competitionId, data } = payload
  return axiosInstance(
    METHOD_TYPES.get,
    LIST(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  )
}

export const getResultService = (payload) => {
  const { competitionId, resultId } = payload

  return axiosInstance(
    METHOD_TYPES.get,
    GET_ONE(competitionId, resultId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.submit,
    }
  )
}

export const createResultService = (payload) => {
  const { competitionId, data } = payload
  return axiosInstance(
    METHOD_TYPES.post,
    CREATE(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم الإضافة بنجاح'
    }
  )
}

export const updateResultService = (payload) => {
  const { competitionId, resultId, data } = payload

  return axiosInstance(
    METHOD_TYPES.put,
    UPDATE(competitionId, resultId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم التعديل بنجاح'
    }
  )
}
export const deleteResultService = (payload) => {
  const { competitionId, resultId } = payload

  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE(competitionId, resultId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم الحذف بنجاح'
    }
  )
}
