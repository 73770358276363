import { createAsyncThunk } from '@reduxjs/toolkit'
import { createEntityService, deleteEntityService, getEntityService, listEntitysService, updateEntityService } from 'network/services/entity.service'
import { ROUTE_PATHS } from 'utils/constants'


export const operatorListEntity = createAsyncThunk('entity/list', async (data, thunkApi) => {
  try {
    return await listEntitysService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorGetEntity = createAsyncThunk('entity/get', async (data, thunkApi) => {
  try {
    return await getEntityService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateEntity = createAsyncThunk('entity/create', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await createEntityService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.entityParentRoute}`)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateEntity = createAsyncThunk('entity/update', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await updateEntityService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.entityParentRoute}`)

    return res

  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorDeleteEntity = createAsyncThunk('entity/delete', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await deleteEntityService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})
