import axiosInstance, { microServices } from 'network/apis'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'

const LIST = (competitionId) => `/v1/competitions/${competitionId}/entities`
const GET_ONE = (competitionId, entityId) => `/v1/competitions/${competitionId}/entities/${entityId}`
const CREATE = (competitionId) => `/v1/competitions/${competitionId}/entities`
const UPDATE = (competitionId, entityId) => `/v1/competitions/${competitionId}/entities/${entityId}`
const DELETE = (competitionId, entityId) => `/v1/competitions/${competitionId}/entities/${entityId}`


export const listEntitysService = (payload) => {
  const { competitionId, data } = payload
  return axiosInstance(
    METHOD_TYPES.get,
    LIST(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  )
}

export const getEntityService = (payload) => {
  const { competitionId, entityId } = payload

  return axiosInstance(
    METHOD_TYPES.get,
    GET_ONE(competitionId, entityId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.submit,
    }
  )
}

export const createEntityService = (payload) => {
  const { competitionId, data } = payload

  return axiosInstance(
    METHOD_TYPES.post,
    CREATE(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم الإضافة بنجاح'
    }
  )
}

export const updateEntityService = (payload) => {
  const { competitionId, entityId, data } = payload

  return axiosInstance(
    METHOD_TYPES.put,
    UPDATE(competitionId, entityId),
    { ...data },
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم التعديل بنجاح'
    }
  )
}
export const deleteEntityService = (payload) => {
  const { competitionId, entityId } = payload

  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE(competitionId, entityId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم الحذف بنجاح'
    }
  )
}
