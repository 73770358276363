import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AppLoader from 'components/ui-kit/AppLoader'
import { LOADER_TYPE } from 'utils/constants'
import CustomRoutes from './routes'
import { makeStyles } from '@mui/styles'
import RequestsInitialization from 'utils/RequestsInitialization'

const useStyles = makeStyles((theme) => ({
  loaderDiv: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    inset: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99999,
  },
}));

const Routes = () => {
  const classes = useStyles()
  return (
    <Router>
      <Suspense fallback={
        <div className={classes.loaderDiv}>
          <AppLoader variant={LOADER_TYPE.SCALE} />
        </div>
      }
      >
        <CustomRoutes />
      </Suspense>

      <RequestsInitialization />
    </Router>
  )
}


export default Routes
