import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./Auth/authSlice";
import hamSlice from "./Ham/hamSlice";
import toasterSlice from "./Toaster/toasterSlice";
import loaderSlice from "./Loader/loaderSlice";
import i18nSlice from "./I18n/I18nSlice";
import dashboardSlice from "./Dashboard/dashboardSlice";
import competitionSlice from "./Competition/competitionSlice";
import usersSlice from "./Users/usersSlice";
import submissionSlice from "./Submission/submissionSlice";
import categorySlice from "./Category/categorySlice";
import sponsorSlice from "./Sponsor/sponsorSlice";
import inquirySlice from "./Inquiry/inquirySlice";
import groupSlice from "./Group/groupSlice";
import stageSlice from "./Stage/stageSlice";
import entitySlice from "./Entity/entitySlice";
import AdminMessagesSlice from "./AdminMessages/AdminMessagesSlice";
import resultSlice from "./Result/resultSlice";
import eventSlice from "./Event/eventSlice";

export const rootReducer = combineReducers({
  auth: authSlice,
  ham: hamSlice,
  toaster: toasterSlice,
  loader: loaderSlice,
  i18n: i18nSlice,
  dashboard: dashboardSlice,
  competition: competitionSlice,
  users: usersSlice,
  submission: submissionSlice,
  category: categorySlice,
  sponsor: sponsorSlice,
  result: resultSlice,
  event: eventSlice,
  inquiry: inquirySlice,
  group: groupSlice,
  stage: stageSlice,
  entity: entitySlice,

  // Added for admin messages
  adminMessage: AdminMessagesSlice,
});
