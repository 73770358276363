import { useEffect } from "react";
import { connect } from "react-redux";
import { userLoaded } from "redux-thunk/redux/Auth/authSlice";
import { operatorProfile } from "redux-thunk/thunk/Auth/Auth";
import { operatorGetActiveCompetition } from "redux-thunk/thunk/Competition/Competition";
import { operatorGetMySubmission } from "redux-thunk/thunk/Submission/Submission";
import { ROUTE_PATHS, USER_ROLES } from "./constants";
import { useLocation, useNavigate } from "react-router-dom";

const RequestsInitialization = ({
  isContestant,
  activeCompetition,
  authToken,
  mustUpdate,
  getMySubmission,
  getProfileData,
  getActiveCompetition,
  userLoaded,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) return;
    // get profile data
    getProfileData();
  }, [authToken, getProfileData]);

  useEffect(() => {
    if (
      mustUpdate !== null &&
      mustUpdate !== undefined &&
      mustUpdate &&
      location.pathname !== ROUTE_PATHS.wizard
    ) {
      navigate(ROUTE_PATHS.wizard);
    }
  }, [location.pathname, mustUpdate, navigate]);

  useEffect(() => {
    getActiveCompetition();
  }, []);

  useEffect(() => {
    userLoaded(true);
  }, []);

  useEffect(() => {
    if (!activeCompetition) return;
    if (!isContestant) return;
    // get initial data
    getMySubmission(activeCompetition.id);
  }, [activeCompetition]);

  return null;
};

const mapStateToProps = (state) => {
  return {
    activeCompetition: state.competition?.activeCompetition,
    authToken: state.auth.token,
    isContestant: [USER_ROLES.contestant].includes(state.auth.role),
    mustUpdate: state.auth.mustUpdate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileData: () => dispatch(operatorProfile()),
    getActiveCompetition: () => dispatch(operatorGetActiveCompetition()),
    userLoaded: (payload) => dispatch(userLoaded(payload)),
    getMySubmission: (payload) => dispatch(operatorGetMySubmission(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsInitialization);
