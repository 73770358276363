import axiosInstance, { microServices } from "network/apis";
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from "utils/constants";

const CREATE = "v1/competitions";
const GET_COMPETITION = "v1/competitions";
const GET_ARCHIVED_COMPETITIONS = "v1/competitions/archived";
const UPDATE_COMPETITION = "v1/competitions";
const GET_ALL = "v1/competitions";
const DELETE = "v1/competitions";
const GET_ACTIVE = "v1/competitions/active";
const CHANGE_COMPETITION_STATUS = (id) => `v1/competitions/${id}/change-status`;
const TOGGLE_COMPETITION_ACTIVATION = (id) =>
  `v1/competitions/${id}/toggle-activation`;
const GET_COMPETITION_STATUS = (id) => `v1/competitions/${id}/stats`;
const GET_COMPETITION_SUBMISSION_SHEET = (id) =>
  `v1/competitions/${id}/submissions/sheet`;

export const getCompetitionService = (id) => {
  return axiosInstance(
    METHOD_TYPES.get,
    `${GET_COMPETITION}/${id}`,
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  );
};

export const getCompetitionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, GET_ALL, data, {
    server: microServices.BASE_URL1,
    handlerEnabled: true,
    loader: LOADER_HANDLER_TYPES.page,
  });
};

export const getArchivedCompetitionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, GET_ARCHIVED_COMPETITIONS, data, {
    server: microServices.BASE_URL1,
    handlerEnabled: true,
    loader: LOADER_HANDLER_TYPES.page,
  });
};

export const createCompetitionService = (data) => {
  return axiosInstance(METHOD_TYPES.post, CREATE, data, {
    server: microServices.BASE_URL1,
    handlerEnabled: true,
    loader: LOADER_HANDLER_TYPES.page,
    successMessage: "تم إنشاء المسابقة بنجاح",
  });
};

export const getActiveCompetitionService = (data) => {
  return axiosInstance(METHOD_TYPES.get, GET_ACTIVE, data, {
    server: microServices.BASE_URL1,
    handlerEnabled: false,
    loader: LOADER_HANDLER_TYPES.page,
  });
};

export const updateCompetitionService = ({ id, ...data }) => {
  return axiosInstance(METHOD_TYPES.put, `${UPDATE_COMPETITION}/${id}`, data, {
    server: microServices.BASE_URL1,
    handlerEnabled: true,
    loader: LOADER_HANDLER_TYPES.page,
    successMessage: "تم تعديل المسابقة بنجاح",
  });
};

export const changeCompetitionStatusService = ({ id, data }) => {
  return axiosInstance(
    METHOD_TYPES.patch,
    CHANGE_COMPETITION_STATUS(id),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: "تم تغيير مرحلة المسابقة بنجاح",
    }
  );
};

export const deleteCompetitionService = (id) => {
  return axiosInstance(
    METHOD_TYPES.delete,
    `${DELETE}/${id}`,
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: "تم حذف المسابقة بنجاح",
    }
  );
};

export const toggleCompetitionActivationService = (id) => {
  return axiosInstance(
    METHOD_TYPES.patch,
    TOGGLE_COMPETITION_ACTIVATION(id),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: "تم تغيير تفعيل المسابقة بنجاح",
    }
  );
};

export const getCompetitionStatsService = ({ competitionId }) => {
  return axiosInstance(
    METHOD_TYPES.get,
    GET_COMPETITION_STATUS(competitionId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  );
};

export const getCompetitionSubmissionSheetService = ({ competitionId }) => {
  return axiosInstance(
    METHOD_TYPES.get,
    GET_COMPETITION_SUBMISSION_SHEET(competitionId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  );
};
