import { createSlice } from '@reduxjs/toolkit'
import { operatorCreateComment, operatorCreateMessage, operatorDeleteComment, operatorDeleteMessage, operatorGetMessage, operatorListMessages, operatorUpdateComment, operatorUpdateMessage } from 'redux-thunk/thunk/AdminMessages/AdminMessages'

const defaultState = {
  page: 1,
  createdAt: 'descending',
  currentMessage: {}
}

const messageSlice = createSlice({
  name: 'messageSlice',
  initialState: defaultState,
  reducers: {
    changeMessagesFilters: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(operatorListMessages.fulfilled, (state, action) => {
        return {
          ...defaultState,
          ...action.payload
        }
      })
      .addCase(operatorGetMessage.fulfilled, (state, action) => {
        return {
          ...state,
          currentMessage: action.payload
        }
      })
      .addCase(operatorCreateMessage.fulfilled, (state, action) => {
        state.docs.push(action.payload)
      })
      .addCase(operatorUpdateMessage.fulfilled, (state, action) => {
        const index = state.docs.findIndex((element, index) => {
          if (element.id === action.meta.arg.messageId) {
            return true
          }
          return false
        })

        state.docs[index] = action.payload
      })
      .addCase(operatorDeleteMessage.fulfilled, (state, action) => {
        state.docs = state.docs.filter((doc) => doc.id !== action.meta.arg.messageId)
      })
      .addCase(operatorCreateComment.fulfilled, (state, action) => {
        return {
          ...defaultState,
          currentMessage: action.payload
        }
      })
      .addCase(operatorUpdateComment.fulfilled, (state, action) => {
        return {
          ...defaultState,
          currentMessage: action.payload
        }
      })
      .addCase(operatorDeleteComment.fulfilled, (state, action) => {
        return {
          ...defaultState,
          currentMessage: action.payload
        }
      })
  }
})
export const { changeMessagesFilters } = messageSlice.actions

export default messageSlice.reducer
