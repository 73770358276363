import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types'

// material-ui
import { alpha, AppBar, Box, IconButton, Paper, Toolbar, Typography } from '@mui/material';

import Sidebar from './DashboardSidebar';
import { handleDrawerToggle } from 'redux-thunk/redux/Ham/hamSlice';
import { GiHamburgerMenu } from 'react-icons/gi';
import { PORTALS } from 'utils/constants';

// ==============================|| MAIN LAYOUT ||============================== //
const MainLayout = ({ handleDrawerToggle }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'start',
            }}
        >
            <Sidebar />


            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    height: '100vh',
                    padding: theme => theme.spacing(2),
                    backgroundColor: theme => alpha(theme.palette.common.black, 0.03),
                }}
            >



                <Paper
                    elevation={0}
                    sx={{
                        width: '100%',
                        pb: 2,
                        border: theme => `1px solid ${theme.palette.divider}`,
                        overflow: 'auto',
                        height: '100%',
                    }}
                >

                    <AppBar elevation={0} position="sticky" color='secondary'>
                        <Toolbar >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}

                                onClick={handleDrawerToggle}
                            >
                                <GiHamburgerMenu />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                <div id={PORTALS.dashboardTitle}></div>
                            </Typography>

                            <div id={PORTALS.dashboardActions}></div>
                        </Toolbar>
                    </AppBar>


                    <Box sx={{ p: 2 }}>
                        <Outlet />
                    </Box>
                </Paper>
            </Box>
        </Box>

    );
};

MainLayout.propTypes = {
    pageTitle: PropTypes.string,
    pageActions: PropTypes.node

}


const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        handleDrawerToggle: () => dispatch(handleDrawerToggle())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
