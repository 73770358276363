import { createAsyncThunk } from '@reduxjs/toolkit'
import { createEventService, deleteEventService, getEventService, listEventsService, updateEventService } from 'network/services/event.service'
import { ROUTE_PATHS } from 'utils/constants'


export const operatorListEvents = createAsyncThunk('event/list', async (data, thunkApi) => {
  try {
    return await listEventsService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorGetEvent = createAsyncThunk('event/get', async (data, thunkApi) => {
  try {
    return await getEventService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateEvent = createAsyncThunk('event/create', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await createEventService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.eventParentRoute}`)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateEvent = createAsyncThunk('event/update', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await updateEventService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.eventParentRoute}`)

    return res

  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorDeleteEvent = createAsyncThunk('event/delete', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await deleteEventService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})
