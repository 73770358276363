import { createSlice } from '@reduxjs/toolkit'
import { operatorLogin, operatorLogout, operatorProfile, operatorRegister, operatorForgetPassword, operatorUpdatePassword, operatorUpdateProfile } from 'redux-thunk/thunk/Auth/Auth'
import { getAuthToken } from 'utils/common-services/cookie.services'
import { USER_ROLES } from 'utils/constants'

const token = getAuthToken()

const defaultAuthState = {
  role: USER_ROLES.visitor
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    token,
    ...defaultAuthState,
  },
  reducers: {
    handleLogout: (state, action) => {
      return {
        ...defaultAuthState,
        userLoaded: true
      }
    },
    userLoaded: (state, action) => {
      return {
        ...state,
        userLoaded: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(operatorLogin.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload,
        }
      })
      .addCase(operatorRegister.fulfilled, (state, action) => {
        return {
          ...defaultAuthState
        } 
      })
      .addCase(operatorForgetPassword.fulfilled, (state, action) => {
        return {
          ...defaultAuthState
        }
      })
      .addCase(operatorLogout.fulfilled, (state, action) => {
        return {
          ...defaultAuthState,
          userLoaded: true
        }
      })
      .addCase(operatorUpdatePassword.fulfilled, (state, action) => {
        return {
          ...defaultAuthState
        }
      })
      .addCase(operatorProfile.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload
        }
      })
      .addCase(operatorUpdateProfile.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload
        }
      })
  }
})

export const { handleLogout, userLoaded } = authSlice.actions

export default authSlice.reducer
