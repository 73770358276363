import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import { alpha } from "@mui/material";
import Logo from "components/ui-kit/Logo";
import { CompetitionStatus, ROUTE_PATHS, USER_ROLES } from "utils/constants";
import { operatorLogout } from "redux-thunk/thunk/Auth/Auth";
import { connect } from "react-redux";
import { AiOutlineUser } from "react-icons/ai";
import { t } from "i18next";

import useScrollTrigger from "@mui/material/useScrollTrigger";

const pages = [
  // {
  //   title: "عن المسابقة",
  //   path: `${ROUTE_PATHS.rootPath}#hero`,
  //   isAnchor: true,
  // },
  // {
  //   title: "اهداف الداتاثون",
  //   path: `${ROUTE_PATHS.rootPath}#objectives`,
  //   isAnchor: true,
  // },
  // {
  //   title: "الفئات المستهدفة",
  //   path: `${ROUTE_PATHS.rootPath}#TargetAudience`,
  //   isAnchor: true,
  // },
  {
    title: "مسارات المسابقة",
    path: `${ROUTE_PATHS.rootPath}#fields`,
    isAnchor: true,
  },
  {
    title: "معايير المسابقة",
    path: `${ROUTE_PATHS.rootPath}#standards`,
    isAnchor: true,
  },
  {
    title: "مراحل المسابقة",
    path: `${ROUTE_PATHS.rootPath}#steps`,
    isAnchor: true,
  },
  // {
  //   title: "مخرجات المسابقة",
  //   path: `${ROUTE_PATHS.results}`,
  //   isAnchor: true,
  // },
  // {
  //     title: "التقديم للمسابقة",
  //     path: ROUTE_PATHS.applyForCompetition
  // },
];

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "unset",
    transition: "unset",
  },
  appBarDefault: {
    backgroundColor: "unset",
    backdropFilter: "unset",
  },
  appBarScrolled: {
    backgroundColor: alpha(theme.palette.common.black, 0.6),
    backdropFilter: "blur(5px)",
  },
}));

const Navbar = ({
  isAuthenticated,
  fullName,
  logoutHandler,
  participation,
  isAdmin,
  activeCompetition,
}) => {
  const navigate = useNavigate();
  const navbarRef = useRef(null);

  const [inHome, setInHome] = useState(true);

  // const [openMoltaqaPopup, setOpenMoltaqaPopup] = useState(false);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
  });

  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    setInHome(location.pathname === "/");
  }, [location, inHome]);

  const classes = useStyles();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = [
    {
      title: "تسجيل حساب جديد",
      path: ROUTE_PATHS.register,
      onClick: () => {
        handleCloseUserMenu();
      },
      hide: isAuthenticated,
    },
    {
      title: "تسجيل الدخول",
      path: ROUTE_PATHS.login,
      onClick: () => {
        handleCloseUserMenu();
      },
      hide: isAuthenticated,
    },
    {
      title: "الملف الشخصي",
      path: ROUTE_PATHS.personalInfo,
      onClick: () => {
        handleCloseUserMenu();
      },
      hide: !isAuthenticated,
    },
    {
      title: "لوحة التحكم",
      path: "/dashboard",
      onClick: () => {
        handleCloseUserMenu();
      },
      hide: !isAuthenticated,
    },
    {
      title: "تسجيل الخروج",
      onClick: () => {
        logoutHandler({ navigate });
        handleCloseUserMenu();
      },
      hide: !isAuthenticated,
    },
  ];

  return (
    <AppBar
      ref={navbarRef}
      className={[
        classes.appBar,
        inHome && !trigger ? classes.appBarDefault : classes.appBarScrolled,
      ].join(" ")}
      position="fixed"
      color="primary"
      enableColorOnDark
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 1,
            }}
          >
            <Link to="/">
              <Logo />
            </Link>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              style={{
                color: "white",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                padding: 0,
              }}
            >
              {pages.map((page, index) => {
                if (page.isAnchor) {
                  return (
                    <MenuItem key={index} onClick={handleCloseNavMenu}>
                      <a key={index} href={page.path}>
                        <Typography textAlign="center">{page.title}</Typography>
                      </a>
                    </MenuItem>
                  );
                }

                return (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <Link key={page} to={page.path}>
                      <Typography textAlign="center">{page.title}</Typography>
                    </Link>
                  </MenuItem>
                );
              })}

              <MenuItem onClick={handleCloseNavMenu}>
                <a
                  href="https://abegs.org/resources"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography textAlign="center">المصادر المفتوحة</Typography>
                </a>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <Link to={ROUTE_PATHS.events}>
                  <Typography textAlign="center">ملتقى الداتاثون</Typography>
                </Link>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <Link to={ROUTE_PATHS.results}>
                  <Typography textAlign="center">مخرجات المسابقة</Typography>
                </Link>
              </MenuItem>

              {activeCompetition &&
                activeCompetition.status === CompetitionStatus.Reception &&
                !isAdmin && (
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link to={ROUTE_PATHS.applyForCompetition}>
                      <Typography textAlign="center">
                        التقديم للمسابقة
                      </Typography>
                    </Link>
                  </MenuItem>
                )}
            </Menu>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-start",
              mr: 1,
              flexGrow: 1,
            }}
          >
            <Link to="/">
              <Logo height="60px" />
            </Link>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "end",
              mr: 3,
              display: { xs: "none", md: "flex" },
              gap: "25px",
            }}
          >
            {pages.map((page, index) => {
              if (page.isAnchor) {
                return (
                  <a key={index} href={page.path}>
                    <Button
                      color="white"
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        color: "white",
                        display: "block",
                        fontSize: "0.8rem",
                      }}
                    >
                      {page.title}
                    </Button>
                  </a>
                );
              }

              return (
                <Link key={index} to={page.path}>
                  <Button
                    color="white"
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page.title}
                  </Button>
                </Link>
              );
            })}

            <a
              href="https://abegs.org/resources"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="white"
                sx={{ my: 2, color: "white", display: "block" }}
              >
                المصادر المفتوحة
              </Button>
            </a>

            <Link to={ROUTE_PATHS.events}>
              <Button
                color="white"
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                }}
              >
                ملتقى الداتاثون
              </Button>
            </Link>

            <Link to={ROUTE_PATHS.results}>
              <Button
                color="white"
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                مخرجات المسابقة
              </Button>
            </Link>

            {activeCompetition &&
              activeCompetition.status === CompetitionStatus.Reception &&
              !isAdmin && (
                <Link to={ROUTE_PATHS.applyForCompetition}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, display: "block" }}
                  >
                    التقديم للمسابقة
                  </Button>
                </Link>
              )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={t("account")}>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0, color: (theme) => theme.palette.white.main }}
              >
                {fullName ? (
                  <Avatar alt={fullName} src="/static/images/avatar/2.jpg" />
                ) : (
                  <AiOutlineUser />
                )}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {isAuthenticated && (
                <MenuItem disabled>
                  <Typography textAlign="center" variant="small">
                    {fullName}
                  </Typography>
                </MenuItem>
              )}
              {settings.map((item, index) => {
                if (item.hide) return null;

                if (item?.path) {
                  return (
                    <MenuItem key={index} onClick={item.onClick}>
                      <Link
                        style={{ display: "block", width: "100%" }}
                        to={item?.path}
                      >
                        <Typography textAlign="center">{item.title}</Typography>
                      </Link>
                    </MenuItem>
                  );
                } else {
                  return (
                    <MenuItem key={index} onClick={item.onClick}>
                      <Typography
                        style={{ display: "block", width: "100%" }}
                        textAlign="center"
                      >
                        {item.title}
                      </Typography>
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token,
    fullName: state.auth.displayName ? state.auth.displayName : null,
    participation: state.submission.mySubmission,
    activeCompetition: state.competition?.activeCompetition,
    isAdmin: state.auth.role === USER_ROLES.admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutHandler: (data) => dispatch(operatorLogout(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
