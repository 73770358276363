import { createSlice } from '@reduxjs/toolkit'
import { operatorChangeCompetitionStatusCompetition, operatorDeleteCompetition, operatorGetActiveCompetition, operatorGetAllCompetitions, operatorGetCompetitionStatsCompetition, operatorToggleActiveCompetition } from 'redux-thunk/thunk/Competition/Competition'

const defaultState = {
  activeCompetition: null,
  page: 1,
  createdAt: 'descending',
  stats: {}
}

const competitionSlice = createSlice({
  name: 'competitionSlice',
  initialState: defaultState,
  reducers: {
    changeCompetitionsFilters: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(operatorGetAllCompetitions.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload
        }
      })
      .addCase(operatorGetActiveCompetition.fulfilled, (state, action) => {
        return {
          ...state,
          activeCompetition: action.payload || {}
        }
      })
      .addCase(operatorDeleteCompetition.fulfilled, (state, action) => {
        state.docs = state.docs.filter((doc) => doc.id !== action.meta.arg)
      })
      .addCase(operatorToggleActiveCompetition.fulfilled, (state, action) => {
        const doc = state.docs.find((doc) => doc.id === action.meta.arg)
        if (doc) {
          doc.active = !doc.active
        }
      })
      .addCase(operatorChangeCompetitionStatusCompetition.fulfilled, (state, action) => {
        const doc = state.docs.find((doc) => doc.id === action.meta.arg.id)
        if (doc) {
          doc.status = action.payload.status
        }
      })
      .addCase(operatorGetCompetitionStatsCompetition.fulfilled, (state, action) => {
        return {
          ...state,
          stats: action.payload
        }
      })
  }
})
export const { changeCompetitionsFilters } = competitionSlice.actions

export default competitionSlice.reducer
