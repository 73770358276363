import { alpha, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/system';
import React from 'react'
import { FaFacebookF, FaTwitter, FaYoutube, FaLinkedinIn, FaEdgeLegacy } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'
import PhoneIcon from '@mui/icons-material/Phone';
import FaxIcon from '@mui/icons-material/Fax';
import EmailIcon from '@mui/icons-material/Email';
const SOCIAL_MEDIA = [
    {
        icon: <FaEdgeLegacy />,
        title: 'ABEGS',
        url: 'https://www.abegs.org/home/',
    },
    {
        icon: <FaFacebookF />,
        title: 'Facebook',
        url: 'https://www.facebook.com/abegsorg',
    },
    {
        icon: <FaTwitter />,
        title: 'Twitter',
        url: 'https://twitter.com/abegsorg',
    },
    {
        icon: <FiInstagram />,
        title: 'Instagram',
        url: 'https://www.instagram.com/_abegsorg',
    },
    {
        icon: <FaYoutube />,
        title: 'Youtube',
        url: 'https://www.youtube.com/abegschanel',
    },
    {
        icon: <FaLinkedinIn />,
        title: 'Linkedin',
        url: 'https://www.linkedin.com/company/abegs',
    },
];


const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: alpha(theme.palette.common.black, 0.1),
        padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
    },
    socialMediaIcon: {
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
}));


export default function Footer() {
    const classes = useStyles();

    return (
        <footer >
            <div className={classes.footer}>
                <Container>
                    <Box className='footer-container-box' sx={{
                        display: 'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        gap:'1rem',
                        textAlign:'center'
                    }}>
                        <Typography variant="body2" gutterBottom>
                          <p>
                            جميع الحقوق محفوظة لمكتب التربية العربي لدول الخليج
                            &copy; {new Date().getFullYear()}
                          </p>

                          <p>
                          المملكة العربية السعودية ،الرياض، طريق الملك سعود، الوزارات
                          </p>

                        </Typography>


                        <div>
                            <Typography  sx={{
                              display: 'flex',
                              justifyContent:'center',
                              alignItems:'center',
                              gap:'5px',
                              marginBottom:'1rem'
                          }} variant="body2" gutterBottom>
                               <span>00966114800555</span>&nbsp; <PhoneIcon/>
                            </Typography>
                            <Typography  sx={{
                              display: 'flex',
                              justifyContent:'center',
                              alignItems:'center',
                              gap:'5px',
                              marginBottom:'1rem'
                          }} variant="body2" gutterBottom>
                               <span>bd@abegs.org</span> &nbsp;<EmailIcon/>
                            </Typography>

                            <Box sx={{
                            display: 'flex',
                            gap: (theme) => theme.spacing(2),
                            fontSize: '1rem',
                            paddingTop:'.5rem'
                        }}>
                            {
                                SOCIAL_MEDIA.map((media, index) => (
                                    <a href={media.url} key={index} className={classes.socialMediaIcon} target="_blank" rel="noreferrer">
                                        {media.icon}
                                    </a>
                                ))
                            }
                        </Box>
                        </div>


                    </Box>
                </Container>
            </div>
        </footer>
    )
}
