import axiosInstance, { microServices } from 'network/apis'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'


const CREATE = (competitionId,submissionId) => `/v1/competitions/${competitionId}/submissions/${submissionId}/messages`
const LIST = (competitionId,submissionId) => `/v1/competitions/${competitionId}/submissions/${submissionId}/messages`
const GET_ONE = (competitionId,submissionId, messageId) => `/v1/competitions/${competitionId}/submissions/${submissionId}/messages/${messageId}`
const UPDATE = (competitionId,submissionId, messageId) => `/v1/competitions/${competitionId}/submissions/${submissionId}/messages/${messageId}`
const DELETE = (competitionId,submissionId, messageId) => `/v1/competitions/${competitionId}/submissions/${submissionId}/messages/${messageId}`

const CREATE_COMMENT = (competitionId,submissionId, messageId) => `/v1/competitions/${competitionId}/submissions/${submissionId}/messages/${messageId}/replies`
const UPDATE_COMMENT = (competitionId,submissionId, messageId, commentId) => `/v1/competitions/${competitionId}/submissions/${submissionId}/messages/${messageId}/replies/${commentId}`
const DELETE_COMMENT = (competitionId,submissionId, messageId, commentId) => `/v1/competitions/${competitionId}/submissions/${submissionId}/messages/${messageId}/replies/${commentId}`


export const listMessagesService = (payload) => {
  const { competitionId,submissionId, ...data } = payload
  return axiosInstance(
    METHOD_TYPES.get,
    LIST(competitionId,submissionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  )
}

export const getMessageService = (payload) => {
  const { competitionId,submissionId, messageId } = payload
  return axiosInstance(
    METHOD_TYPES.get,
    GET_ONE(competitionId,submissionId, messageId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  )
}

export const createMessageService = (payload) => {
  const { competitionId , submissionId, data } = payload

  return axiosInstance(
    METHOD_TYPES.post,
    CREATE(competitionId,submissionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم إرسال الرسالة بنجاح'
    }
  )
}

export const updateMessageService = (payload) => {
  const { competitionId,submissionId, messageId, data } = payload
  return axiosInstance(
    METHOD_TYPES.put,
    UPDATE(competitionId,submissionId, messageId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تعديل الرسالة بنجاح'
    }
  )
}

export const deleteMessageService = (payload) => {
  const { competitionId,submissionId, messageId } = payload

  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE(competitionId,submissionId, messageId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم الحذف بنجاح'
    }
  )
}

export const createCommentService = (payload) => {
  const { competitionId,submissionId, messageId, data } = payload

  return axiosInstance(
    METHOD_TYPES.post,
    CREATE_COMMENT(competitionId,submissionId, messageId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم إضافة التعليق بنجاح'
    }
  )
}


export const updateCommentService = (payload) => {
  const { competitionId, messageId, commentId, data } = payload

  return axiosInstance(
    METHOD_TYPES.put,
    UPDATE_COMMENT(competitionId, messageId, commentId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تعديل التعليق بنجاح'
    }
  )
}

export const deleteCommentService = (payload) => {
  const { competitionId, messageId, commentId } = payload

  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE_COMMENT(competitionId, messageId, commentId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  )
}
