import { lazy } from "react";
import { AiOutlineCodeSandbox, AiOutlineControl } from "react-icons/ai";
import { BiQuestionMark } from "react-icons/bi";
import { ImCheckboxChecked } from "react-icons/im";
import { BsFilesAlt, BsInfo } from "react-icons/bs";
import { FaBoxes, FaRegNewspaper, FaUsers } from "react-icons/fa";
import { FiZap } from "react-icons/fi";
import { TbMessage } from "react-icons/tb";
import { ROUTE_PATHS, USER_ROLES } from "utils/constants";
import { v4 as uuidv4 } from "uuid";

import MessageIcon from "@mui/icons-material/Message";

export const routerList = [
  {
    key: uuidv4(),
    label: "home",
    path: ROUTE_PATHS.rootPath,
    component: lazy(() => import("pages/Home")),
    hasAuth: false,
    hasSideBarOption: false,
  },
  {
    key: uuidv4(),
    label: "events",
    path: ROUTE_PATHS.events,
    component: lazy(() => import("pages/Events")),
    hasAuth: false,
    hasSideBarOption: false,
  },
  {
    key: uuidv4(),
    label: "activeResults",
    path: ROUTE_PATHS.results,
    component: lazy(() => import("pages/Results")),
    hasAuth: false,
    hasSideBarOption: false,
  },
  {
    key: uuidv4(),
    label: "terms",
    path: ROUTE_PATHS.terms,
    component: lazy(() => import("pages/Terms")),
    hasAuth: false,
    hasSideBarOption: false,
  },
  {
    key: uuidv4(),
    label: "apply",
    path: ROUTE_PATHS.applyForCompetition,
    component: lazy(() => import("pages/Apply")),
    allowedRoles: [USER_ROLES.contestant, USER_ROLES.null, USER_ROLES.visitor],
    hasAuth: true,
    hasSideBarOption: false,
  },
];

export const dashboardRoutes = [
  {
    key: uuidv4(),
    label: "dashboard",
    path: ROUTE_PATHS.dashboard,
    component: lazy(() => import("pages/Dashboard")),
    hasAuth: true,
    hasSideBarOption: false,
    allowedRoles: [
      USER_ROLES.admin,
      USER_ROLES.secretary,
      USER_ROLES.contestant,
    ],
    icon: <AiOutlineControl />,
    showInSideBar: true,
  },
  {
    key: uuidv4(),
    label: "personalInfo",
    path: ROUTE_PATHS.personalInfo,
    component: lazy(() => import("pages/Dashboard/PersonalInfo")),
    hasAuth: true,
    hasSideBarOption: false,
    allowedRoles: [
      USER_ROLES.admin,
      USER_ROLES.secretary,
      USER_ROLES.contestant,
    ],
    icon: <BsInfo />,
    showInSideBar: true,
  },
  {
    key: uuidv4(),
    label: "users",
    path: ROUTE_PATHS.users,
    component: lazy(() => import("pages/Dashboard/Users")),
    hasAuth: true,
    hasSideBarOption: false,
    allowedRoles: [USER_ROLES.admin],
    icon: <FaUsers />,
    showInSideBar: true,
  },
  {
    key: uuidv4(),
    label: "singleUser",
    path: ROUTE_PATHS.singleUser,
    component: lazy(() => import("pages/Dashboard/Users/SingleUser")),
    hasAuth: true,
    hasSideBarOption: false,
    allowedRoles: [USER_ROLES.admin],
  },
  {
    key: uuidv4(),
    label: "updateUser",
    path: ROUTE_PATHS.updateUser,
    component: lazy(() => import("pages/Dashboard/Users/UpdateUser")),
    hasAuth: true,
    hasSideBarOption: false,
    allowedRoles: [USER_ROLES.admin],
  },
  {
    key: uuidv4(),
    label: "newUser",
    path: ROUTE_PATHS.newUser,
    component: lazy(() => import("pages/Dashboard/Users/NewUser")),
    hasAuth: true,
    hasSideBarOption: false,
    allowedRoles: [USER_ROLES.admin],
  },
  {
    key: uuidv4(),
    label: "competitions",
    path: ROUTE_PATHS.competitions,
    component: lazy(() => import("pages/Dashboard/Competitions")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    icon: <AiOutlineCodeSandbox />,
    showInSideBar: true,
  },
  {
    key: uuidv4(),
    label: "newCompetition",
    path: ROUTE_PATHS.newCompetition,
    component: lazy(() =>
      import("pages/Dashboard/Competitions/NewCompetition")
    ),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
  },
  {
    key: uuidv4(),
    label: "updateCompetition",
    path: ROUTE_PATHS.updateCompetition,
    component: lazy(() =>
      import("pages/Dashboard/Competitions/UpdateCompetition")
    ),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
  },
  {
    key: uuidv4(),
    label: "singleCompetition",
    path: ROUTE_PATHS.singleCompetition,
    component: lazy(() =>
      import("pages/Dashboard/Competitions/SingleCompetition")
    ),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
  },
  {
    key: uuidv4(),
    label: "activeCompetition",
    path: ROUTE_PATHS.activeCompetition,
    component: lazy(() =>
      import("pages/Dashboard/Competitions/ActiveCompetition")
    ),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin, USER_ROLES.contestant],
    hasSideBarOption: false,
  },

  //* CATEGORIES
  {
    key: uuidv4(),
    label: "categories",
    path: ROUTE_PATHS.category,
    component: lazy(() => import("pages/Dashboard/Category")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    icon: <FaBoxes />,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "newCategory",
    path: ROUTE_PATHS.newCategory,
    component: lazy(() => import("pages/Dashboard/Category/NewCategory")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "singleCategory",
    path: ROUTE_PATHS.singleCategory,
    component: lazy(() => import("pages/Dashboard/Category/SingleCategory")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "updateCategory",
    path: ROUTE_PATHS.updateCategory,
    component: lazy(() => import("pages/Dashboard/Category/UpdateCategory")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },

  //* sponsors
  {
    key: uuidv4(),
    label: "sponsors",
    path: ROUTE_PATHS.sponsor,
    component: lazy(() => import("pages/Dashboard/Sponsor")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    icon: <FaBoxes />,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "newSponsor",
    path: ROUTE_PATHS.newSponsor,
    component: lazy(() => import("pages/Dashboard/Sponsor/NewSponsor")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "singleSponsor",
    path: ROUTE_PATHS.singleSponsor,
    component: lazy(() => import("pages/Dashboard/Sponsor/SingleSponsor")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "updateSponsor",
    path: ROUTE_PATHS.updateSponsor,
    component: lazy(() => import("pages/Dashboard/Sponsor/UpdateSponsor")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },

  //* results
  {
    key: uuidv4(),
    label: "results",
    path: ROUTE_PATHS.result,
    component: lazy(() => import("pages/Dashboard/Result")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    icon: <FaBoxes />,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "newResult",
    path: ROUTE_PATHS.newResult,
    component: lazy(() => import("pages/Dashboard/Result/NewResult")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "singleResult",
    path: ROUTE_PATHS.singleResult,
    component: lazy(() => import("pages/Dashboard/Result/SingleResult")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "updateResult",
    path: ROUTE_PATHS.updateResult,
    component: lazy(() => import("pages/Dashboard/Result/UpdateResult")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },

  //* events
  {
    key: uuidv4(),
    label: "events",
    path: ROUTE_PATHS.event,
    component: lazy(() => import("pages/Dashboard/Event")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    icon: <FaBoxes />,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "newEvent",
    path: ROUTE_PATHS.newEvent,
    component: lazy(() => import("pages/Dashboard/Event/NewEvent")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "singleEvent",
    path: ROUTE_PATHS.singleEvent,
    component: lazy(() => import("pages/Dashboard/Event/SingleEvent")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "updateEvent",
    path: ROUTE_PATHS.updateEvent,
    component: lazy(() => import("pages/Dashboard/Event/UpdateEvent")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },

  //* groups
  {
    key: uuidv4(),
    label: "groups",
    path: ROUTE_PATHS.group,
    component: lazy(() => import("pages/Dashboard/Group")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    icon: <FaBoxes />,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "newGroup",
    path: ROUTE_PATHS.newGroup,
    component: lazy(() => import("pages/Dashboard/Group/NewGroup")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "singleGroup",
    path: ROUTE_PATHS.singleGroup,
    component: lazy(() => import("pages/Dashboard/Group/SingleGroup")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "updateGroup",
    path: ROUTE_PATHS.updateGroup,
    component: lazy(() => import("pages/Dashboard/Group/UpdateGroup")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },

  //* stages
  {
    key: uuidv4(),
    label: "stages",
    path: ROUTE_PATHS.stage,
    component: lazy(() => import("pages/Dashboard/Stage")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    icon: <FaBoxes />,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "newStage",
    path: ROUTE_PATHS.newStage,
    component: lazy(() => import("pages/Dashboard/Stage/NewStage")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "singleStage",
    path: ROUTE_PATHS.singleStage,
    component: lazy(() => import("pages/Dashboard/Stage/SingleStage")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "updateStage",
    path: ROUTE_PATHS.updateStage,
    component: lazy(() => import("pages/Dashboard/Stage/UpdateStage")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },

  //* submission
  {
    key: uuidv4(),
    label: "submissions",
    path: ROUTE_PATHS.submission,
    component: lazy(() => import("pages/Dashboard/Submission")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin, USER_ROLES.secretary],
    hasSideBarOption: false,
    icon: <ImCheckboxChecked />,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "submissions",
    path: ROUTE_PATHS.usersSubmission,
    component: lazy(() => import("pages/Dashboard/Submission")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.secretary],
    hasSideBarOption: false,
    icon: <ImCheckboxChecked />,
    showInSideBar: true,
  },
  {
    key: uuidv4(),
    label: "singleSubmission",
    path: ROUTE_PATHS.singleSubmission,
    component: lazy(() =>
      import("pages/Dashboard/Submission/SingleSubmission")
    ),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin, USER_ROLES.secretary],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "mySubmissionUpdate",
    path: ROUTE_PATHS.updateParticipation,
    component: lazy(() =>
      import("pages/Dashboard/Participation/UpdateParticipation")
    ),
    hasAuth: true,
    allowedRoles: [USER_ROLES.contestant],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "mySubmission",
    path: ROUTE_PATHS.participation,
    component: lazy(() => import("pages/Dashboard/Participation")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.contestant],
    hasSideBarOption: false,
    icon: <ImCheckboxChecked />,
    showInSideBar: true,
  },

  // Admin Messages
  {
    key: uuidv4(),
    label: "messages",
    path: ROUTE_PATHS.messages,
    component: lazy(() => import("pages/Dashboard/Submission/AdminMessages")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.contestant],
    hasSideBarOption: false,
    icon: <MessageIcon />,
    showInSideBar: true,
  },
  {
    key: uuidv4(),
    label: "singleMessage",
    path: ROUTE_PATHS.singleMessage,
    component: lazy(() =>
      import("pages/Dashboard/Submission/AdminMessages/SingleAdminMessage")
    ),
    hasAuth: true,
    allowedRoles: [
      USER_ROLES.admin,
      USER_ROLES.contestant,
      USER_ROLES.secretary,
    ],
    hasSideBarOption: false,
  },
  {
    key: uuidv4(),
    label: "updateMessage",
    path: ROUTE_PATHS.updateMessage,
    component: lazy(() =>
      import("pages/Dashboard/Submission/AdminMessages/UpdateAdminMessage")
    ),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin, USER_ROLES.secretary],
    hasSideBarOption: false,
  },
  {
    key: uuidv4(),
    label: "newMessage",
    path: ROUTE_PATHS.newMessage,
    component: lazy(() =>
      import("pages/Dashboard/Submission/AdminMessages/NewAdminMessage")
    ),
    hasAuth: true,
    allowedRoles: [
      USER_ROLES.admin,
      USER_ROLES.contestant,
      USER_ROLES.secretary,
    ],
    hasSideBarOption: false,
  },
  // End Of Admin Messages

  //* entities
  {
    key: uuidv4(),
    label: "entities",
    path: ROUTE_PATHS.entity,
    component: lazy(() => import("pages/Dashboard/Entity")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    icon: <FaBoxes />,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "newEntity",
    path: ROUTE_PATHS.newEntity,
    component: lazy(() => import("pages/Dashboard/Entity/NewEntity")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "singleEntity",
    path: ROUTE_PATHS.singleEntity,
    component: lazy(() => import("pages/Dashboard/Entity/SingleEntity")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },
  {
    key: uuidv4(),
    label: "updateEntity",
    path: ROUTE_PATHS.updateEntity,
    component: lazy(() => import("pages/Dashboard/Entity/UpdateEntity")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin],
    hasSideBarOption: false,
    showInSideBar: false,
  },

  {
    key: uuidv4(),
    label: "inquiries",
    path: ROUTE_PATHS.inquiries,
    component: lazy(() => import("pages/Dashboard/Inquiries")),
    hasAuth: true,
    allowedRoles: [
      USER_ROLES.admin,
      USER_ROLES.contestant,
      USER_ROLES.secretary,
    ],
    hasSideBarOption: false,
    icon: <BiQuestionMark />,
    showInSideBar: true,
  },
  {
    key: uuidv4(),
    label: "singleInquiry",
    path: ROUTE_PATHS.singleInquiry,
    component: lazy(() => import("pages/Dashboard/Inquiries/SingleInquiry")),
    hasAuth: true,
    allowedRoles: [
      USER_ROLES.admin,
      USER_ROLES.contestant,
      USER_ROLES.secretary,
    ],
    hasSideBarOption: false,
  },
  {
    key: uuidv4(),
    label: "updateInquiry",
    path: ROUTE_PATHS.updateInquiry,
    component: lazy(() => import("pages/Dashboard/Inquiries/UpdateInquiry")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.admin, USER_ROLES.secretary],
    hasSideBarOption: false,
  },
  {
    key: uuidv4(),
    label: "newInquiry",
    path: ROUTE_PATHS.newInquiry,
    component: lazy(() => import("pages/Dashboard/Inquiries/NewInquiry")),
    hasAuth: true,
    allowedRoles: [
      USER_ROLES.admin,
      USER_ROLES.contestant,
      USER_ROLES.secretary,
    ],
    hasSideBarOption: false,
  },
];

export const authRoutes = [
  {
    key: uuidv4(),
    label: "login",
    path: ROUTE_PATHS.login,
    component: lazy(() => import("pages/Auth/Login")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.visitor],
    hasSideBarOption: false,
    subMenus: [],
  },
  {
    key: uuidv4(),
    label: "register",
    path: ROUTE_PATHS.register,
    component: lazy(() => import("pages/Auth/Register")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.visitor],
    hasSideBarOption: false,
    subMenus: [],
  },
  {
    key: uuidv4(),
    label: "forgetPassword",
    path: ROUTE_PATHS.forgetPassword,
    component: lazy(() => import("pages/Auth/ForgetPassword")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.visitor],
    hasSideBarOption: false,
    subMenus: [],
  },
  {
    key: uuidv4(),
    label: "resetCode",
    path: ROUTE_PATHS.resetCode,
    component: lazy(() => import("pages/Auth/ResetCode")),
    hasAuth: true,
    allowedRoles: [USER_ROLES.visitor],
    hasSideBarOption: false,
    subMenus: [],
  },
  {
    key: uuidv4(),
    label: "wizard",
    path: ROUTE_PATHS.wizard,
    component: lazy(() => import("pages/Wizard")),
    hasAuth: true,
    hasSideBarOption: false,
    subMenus: [],
  },
];
