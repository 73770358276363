import { createSlice } from '@reduxjs/toolkit'
import { operatorCreateUser, operatorDeleteUser, operatorGetUsers, operatorToggleActiveUser, operatorUpdateUser } from 'redux-thunk/thunk/Users/Users'

const defaultState = {
  page: 1,
  createdAt: 'descending'
}

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState: defaultState,
  reducers: {
    changeUsersFilters: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(operatorGetUsers.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload
        }
      })
      .addCase(operatorToggleActiveUser.fulfilled, (state, action) => {
        const doc = state.docs.find((doc) => doc.id === action.meta.arg)
        if (doc) {
          doc.active = !doc.active
        }
      })
      .addCase(operatorCreateUser.fulfilled, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(operatorUpdateUser.fulfilled, (state, action) => {
        return {
          ...state,
        }
      })
      .addCase(operatorDeleteUser.fulfilled, (state, action) => {
        state.docs = state.docs.filter((doc) => doc.id !== action.meta.arg)
      })
  }
})
export const { changeUsersFilters } = usersSlice.actions

export default usersSlice.reducer
