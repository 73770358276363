import axiosInstance, { microServices } from 'network/apis'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'

const LIST = (competitionId) => `/v1/competitions/${competitionId}/categories`
const GET_ONE = (competitionId, categoryId) => `/v1/competitions/${competitionId}/categories/${categoryId}`
const CREATE = (competitionId) => `/v1/competitions/${competitionId}/categories`
const UPDATE = (competitionId, categoryId) => `/v1/competitions/${competitionId}/categories/${categoryId}`
const DELETE = (competitionId, categoryId) => `/v1/competitions/${competitionId}/categories/${categoryId}`


export const listCategoriesService = (payload) => {
  const { competitionId, data } = payload
  return axiosInstance(
    METHOD_TYPES.get,
    LIST(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  )
}

export const getCategoryService = (payload) => {
  const { competitionId, categoryId } = payload

  return axiosInstance(
    METHOD_TYPES.get,
    GET_ONE(competitionId, categoryId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.submit,
    }
  )
}

export const createCategoryService = (payload) => {
  const { competitionId, data } = payload

  return axiosInstance(
    METHOD_TYPES.post,
    CREATE(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم إضافة المجال بنجاح'
    }
  )
}

export const updateCategoryService = (payload) => {
  const { competitionId, categoryId, data } = payload

  return axiosInstance(
    METHOD_TYPES.put,
    UPDATE(competitionId, categoryId),
    { ...data },
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تعديل المجال بنجاح'
    }
  )
}
export const deleteCategoryService = (payload) => {
  const { competitionId, categoryId } = payload

  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE(competitionId, categoryId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم حذف المجال بنجاح'
    }
  )
}
