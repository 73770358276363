import axiosInstance, { microServices } from 'network/apis'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'

const LIST = (competitionId) => `/v1/competitions/${competitionId}/sponsors`
const GET_ONE = (competitionId, sponsorId) => `/v1/competitions/${competitionId}/sponsors/${sponsorId}`
const CREATE = (competitionId) => `/v1/competitions/${competitionId}/sponsors`
const UPDATE = (competitionId, sponsorId) => `/v1/competitions/${competitionId}/sponsors/${sponsorId}`
const DELETE = (competitionId, sponsorId) => `/v1/competitions/${competitionId}/sponsors/${sponsorId}`


export const listSponsorsService = (payload) => {
  const { competitionId, data } = payload
  return axiosInstance(
    METHOD_TYPES.get,
    LIST(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  )
}

export const getSponsorService = (payload) => {
  const { competitionId, sponsorId } = payload

  return axiosInstance(
    METHOD_TYPES.get,
    GET_ONE(competitionId, sponsorId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.submit,
    }
  )
}

export const createSponsorService = (payload) => {
  const { competitionId, data } = payload
  return axiosInstance(
    METHOD_TYPES.post,
    CREATE(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم إضافة الراعي بنجاح'
    }
  )
}

export const updateSponsorService = (payload) => {
  const { competitionId, sponsorId, data } = payload

  return axiosInstance(
    METHOD_TYPES.put,
    UPDATE(competitionId, sponsorId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تعديل الراعي بنجاح'
    }
  )
}
export const deleteSponsorService = (payload) => {
  const { competitionId, sponsorId } = payload

  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE(competitionId, sponsorId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم حذف الراعي بنجاح'
    }
  )
}
