import { createSlice } from '@reduxjs/toolkit'
import { operatorCreateCategory, operatorDeleteCategory, operatorGetCategory, operatorListCategories, operatorUpdateCategory } from 'redux-thunk/thunk/Category/Category'

const defaultState = {}

const categorySlice = createSlice({
  name: 'categorySlice',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(operatorListCategories.fulfilled, (state, action) => {
        return {
          ...defaultState,
          categories: action.payload
        }
      })
      .addCase(operatorGetCategory.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorCreateCategory.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorUpdateCategory.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorDeleteCategory.fulfilled, (state, action) => {
        state.categories = state.categories.filter((doc) => doc.id !== action.meta.arg.categoryId)
      })
  }
})

export default categorySlice.reducer
