import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  loginService,
  profileService,
  registerService,
  forgetPasswordService,
  updatePasswordService,
  resetPasswordService,
  updateProfileService,
  socialLoginService
} from 'network/services/auth.service'
import { ROUTE_PATHS } from 'utils/constants'
import { setAuthToken, signOut } from 'utils/common-services/cookie.services'

/**
 * Operator Login Thunk
 */
export const operatorLogin = createAsyncThunk('auth/login', async ({ email, password, rememberMe, navigate }, thunkApi) => {
  try {
    const res = await loginService({ email, password })

    // if (rememberMe) {
      setAuthToken(res.token)
    // }


    navigate(ROUTE_PATHS.rootPath, { replace: true })
    return res
  } catch (error) {
    // return thunkApi.rejectWithValue(error[0].description)
    return thunkApi.rejectWithValue(error.message)
  }
});

export const operatorSocialLogin = createAsyncThunk('auth/social-login', () => {
  socialLoginService();
});


/**
 * Operator Register Thunk
 */
export const operatorRegister = createAsyncThunk('auth/register', async ({ data, navigate }, thunkApi) => {
  try {
    const res = await registerService({ ...data })

    navigate(ROUTE_PATHS.login, {
      replace: true
    })

    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.message)
  }
})


/**
 * Operator forget Password Thunk
 */
export const operatorForgetPassword = createAsyncThunk('auth/forget-password', async (data, thunkApi) => {
  try {
    return await forgetPasswordService(data)
  } catch (error) {
    // return thunkApi.rejectWithValue(error[0].description)
    return thunkApi.rejectWithValue(error.message)
  }
})


/**
 * Operator Reset Password Thunk
 */
export const operatorResetPassword = createAsyncThunk('auth/reset-password', async ({ navigate, ...data }, thunkApi) => {
  try {
    const res = await resetPasswordService(data)

    navigate(ROUTE_PATHS.login, {
      replace: true
    })

    return res
  } catch (error) {
    // return thunkApi.rejectWithValue(error[0].description)
    return thunkApi.rejectWithValue(error.message)
  }
})

/**
 * Operator Logout Thunk
 */
export const operatorLogout = createAsyncThunk('auth/logout', async ({ navigate }, thunkApi) => {
  try {
    signOut()
    navigate && navigate(ROUTE_PATHS.rootPath, {
      replace: true
    })
    // const res = await logoutService()
    // return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

/**
 * Operator Change Password Thunk
 */
export const operatorUpdatePassword = createAsyncThunk('update/password', async ({ oldPassword, newPassword }, thunkApi) => {
  try {
    const res = await updatePasswordService({ oldPassword, newPassword })
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

/**
 * Operator Profile Thunk
 */
export const operatorProfile = createAsyncThunk('auth/profile', async (thunkApi) => {
  try {
    return await profileService()
  } catch (error) {
    // return thunkApi.rejectWithValue(error[0].description)
    return thunkApi.rejectWithValue(error.message)
  }
})


/**
 * Operator Profile update Thunk
 */
export const operatorUpdateProfile = createAsyncThunk('auth/profile-update', async (data, thunkApi) => {
  try {
    return await updateProfileService(data)
  } catch (error) {
    // return thunkApi.rejectWithValue(error[0].description)
    return thunkApi.rejectWithValue(error.message)
  }
})

