import { createSlice } from '@reduxjs/toolkit'
import { operatorCreateStage, operatorDeleteStage, operatorGetStage, operatorListStages, operatorUpdateStage } from 'redux-thunk/thunk/Stage/Stage'

const defaultState = {}

const stageSlice = createSlice({
  name: 'stageSlice',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(operatorListStages.fulfilled, (state, action) => {
        return {
          ...defaultState,
          stages: action.payload
        }
      })
      .addCase(operatorGetStage.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorCreateStage.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorUpdateStage.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorDeleteStage.fulfilled, (state, action) => {
        state.stages = state.stages.filter((doc) => doc.id !== action.meta.arg.stageId)
      })
  }
})

export default stageSlice.reducer
