import axiosInstance, { microServices } from 'network/apis'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'

const GET_ALL = (id) => `/v1/competitions/${id}/submissions`
const CREATE = (id) => `/v1/competitions/${id}/submissions`
const DELETE = (competitionId, submissionId) => `/v1/competitions/${competitionId}/submissions/${submissionId}`
const GET_ONE = (competitionId, submissionId) => `/v1/competitions/${competitionId}/submissions/${submissionId}`
const UPDATE = (competitionId, submissionId) => `/v1/competitions/${competitionId}/submissions/${submissionId}`
const GET_MINE = (id) => `/v1/competitions/${id}/submissions/me`
const DELETE_ATTACHMENT = (competitionId, submissionId, attachmentId) => `v1/competitions/${competitionId}/submissions/${submissionId}/attachments/${attachmentId}`
const ADD_ATTACHMENT = (competitionId, submissionId) => `v1/competitions/${competitionId}/submissions/${submissionId}/attachments/`
const ACCEPT_SUBMISSION = (competitionId, submissionId) => `v1/competitions/${competitionId}/submissions/${submissionId}`


export const getSubmissionsService = ({ competitionId, ...data }) => {
  return axiosInstance(
    METHOD_TYPES.get,
    GET_ALL(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.data
    }
  )
}

export const createSubmissionService = ({ id, data }) => {
  return axiosInstance(
    METHOD_TYPES.post,
    CREATE(id),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم التقديم بنجاح'
    }
  )
}

export const updateSubmissionService = ({ competitionId, submissionId, data }) => {
  return axiosInstance(
    METHOD_TYPES.put,
    UPDATE(competitionId, submissionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم التعديل بنجاح'
    }
  )
}

export const deleteSubmissionService = ({ competitionId, submissionId }) => {
  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE(competitionId, submissionId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم حذف المشاركة بنجاح'
    }
  )
}


export const getSubmissionService = ({ competitionId, submissionId }) => {
  return axiosInstance(
    METHOD_TYPES.get,
    GET_ONE(competitionId, submissionId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page
    }
  )
}


export const getMySubmissionService = (id) => {
  return axiosInstance(
    METHOD_TYPES.get,
    GET_MINE(id),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.submit,
    }
  )
}


export const deleteAttachmentService = (payload) => {
  const { competitionId, submissionId, attachmentId } = payload

  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE_ATTACHMENT(competitionId, submissionId, attachmentId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم الحذف بنجاح'
    }
  )
}

export const addAttachmentService = (payload) => {
  const { competitionId, submissionId, data } = payload

  return axiosInstance(
    METHOD_TYPES.post,
    ADD_ATTACHMENT(competitionId, submissionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم الإضافة بنجاح'
    }
  )
}
export const changeSubmissionStatusService = (payload) => {
  const { competitionId, submissionId, data } = payload

  return axiosInstance(
    METHOD_TYPES.patch,
    ACCEPT_SUBMISSION(competitionId, submissionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تغيير حالة التقديم بنجاح'
    }
  )
}
