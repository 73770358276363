import axiosInstance, { microServices } from 'network/apis'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'

const CREATE = (competitionId) => `/v1/competitions/${competitionId}/inquiries`
const LIST = (competitionId) => `/v1/competitions/${competitionId}/inquiries`
const GET_ONE = (competitionId, inquiryId) => `/v1/competitions/${competitionId}/inquiries/${inquiryId}`
const UPDATE = (competitionId, inquiryId) => `/v1/competitions/${competitionId}/inquiries/${inquiryId}`
const DELETE = (competitionId, inquiryId) => `/v1/competitions/${competitionId}/inquiries/${inquiryId}`

const CREATE_COMMENT = (competitionId, inquiryId) => `/v1/competitions/${competitionId}/inquiries/${inquiryId}/comments`
const UPDATE_COMMENT = (competitionId, inquiryId, commentId) => `/v1/competitions/${competitionId}/inquiries/${inquiryId}/comments/${commentId}`
const DELETE_COMMENT = (competitionId, inquiryId, commentId) => `/v1/competitions/${competitionId}/inquiries/${inquiryId}/comments/${commentId}`


export const listInquiriesService = (payload) => {
  const { competitionId, ...data } = payload
  return axiosInstance(
    METHOD_TYPES.get,
    LIST(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  )
}

export const getInquiryService = (payload) => {
  const { competitionId, inquiryId } = payload
  return axiosInstance(
    METHOD_TYPES.get,
    GET_ONE(competitionId, inquiryId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.submit,
    }
  )
}

export const createInquiryService = (payload) => {
  const { competitionId, data } = payload

  return axiosInstance(
    METHOD_TYPES.post,
    CREATE(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم إضافة الاستفسار بنجاح'
    }
  )
}

export const updateInquiryService = (payload) => {
  const { competitionId, inquiryId, data } = payload

  return axiosInstance(
    METHOD_TYPES.put,
    UPDATE(competitionId, inquiryId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تعديل الاستفسار بنجاح'
    }
  )
}

export const deleteInquiryService = (payload) => {
  const { competitionId, inquiryId } = payload

  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE(competitionId, inquiryId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم الحذف بنجاح'
    }
  )
}


export const createCommentService = (payload) => {
  const { competitionId, inquiryId, data } = payload

  return axiosInstance(
    METHOD_TYPES.post,
    CREATE_COMMENT(competitionId, inquiryId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم إضافة التعليق بنجاح'
    }
  )
}


export const updateCommentService = (payload) => {
  const { competitionId, inquiryId, commentId, data } = payload

  return axiosInstance(
    METHOD_TYPES.put,
    UPDATE_COMMENT(competitionId, inquiryId, commentId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تعديل التعليق بنجاح'
    }
  )
}

export const deleteCommentService = (payload) => {
  const { competitionId, inquiryId, commentId } = payload

  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE_COMMENT(competitionId, inquiryId, commentId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  )
}
