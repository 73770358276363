import { createAsyncThunk } from '@reduxjs/toolkit'
import { createCommentService, createInquiryService, deleteCommentService, deleteInquiryService, getInquiryService, listInquiriesService, updateCommentService, updateInquiryService } from 'network/services/inquiry.service'
import { ROUTE_PATHS } from 'utils/constants'


export const operatorListInquiries = createAsyncThunk('inquiry/list', async (data, thunkApi) => {
  try {
    return await listInquiriesService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorGetInquiry = createAsyncThunk('inquiry/get', async (data, thunkApi) => {
  try {
    return await getInquiryService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateInquiry = createAsyncThunk('inquiry/create', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await createInquiryService(payload)
    navigate(ROUTE_PATHS.inquiries)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateInquiry = createAsyncThunk('inquiry/update', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await updateInquiryService(payload)
    navigate(ROUTE_PATHS.inquiries)
    return res

  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorDeleteInquiry = createAsyncThunk('inquiry/delete', async (payload, thunkApi) => {
  try {
    const res = await deleteInquiryService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateComment = createAsyncThunk('inquiry/createComment', async (payload, thunkApi) => {
  try {
    const res = await createCommentService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateComment = createAsyncThunk('inquiry/updateComment', async (payload, thunkApi) => {
  try {
    const res = await updateCommentService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorDeleteComment = createAsyncThunk('inquiry/deleteComment', async (payload, thunkApi) => {
  try {
    const res = await deleteCommentService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})
