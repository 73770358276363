import { createSlice } from '@reduxjs/toolkit'
import { operatorCreateGroup, operatorDeleteGroup, operatorGetGroup, operatorListGroups, operatorUpdateGroup } from 'redux-thunk/thunk/Group/Group'

const defaultState = {}

const groupSlice = createSlice({
  name: 'groupSlice',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(operatorListGroups.fulfilled, (state, action) => {
        return {
          ...defaultState,
          groups: action.payload
        }
      })
      .addCase(operatorGetGroup.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorCreateGroup.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorUpdateGroup.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorDeleteGroup.fulfilled, (state, action) => {
        state.groups = state.groups.filter((doc) => doc.id !== action.meta.arg.groupId)
      })
  }
})

export default groupSlice.reducer
