import { createAsyncThunk } from '@reduxjs/toolkit'
import { createUserService, deleteUserService, getUserService, getUsersService, toggleActiveUserService, updateUserService } from 'network/services/users.service'
import { ROUTE_PATHS } from 'utils/constants'
/**
 * Operator get users Thunk
 */
export const operatorGetUsers = createAsyncThunk('users/getAll', async (data, thunkApi) => {
  try {
    const res = await getUsersService(data)

    return res
  } catch (error) {
    // return thunkApi.rejectWithValue(error[0].description)
    return thunkApi.rejectWithValue(error.description)
  }
})


export const operatorToggleActiveUser = createAsyncThunk('users/toggleActiveUser', async (data, thunkApi) => {
  try {
    const res = await toggleActiveUserService(data)

    return res
  } catch (error) {
    // return thunkApi.rejectWithValue(error[0].description)
    return thunkApi.rejectWithValue(error.description)
  }
})


export const operatorGetUser = createAsyncThunk('users/getUser', async (id, thunkApi) => {
  try {
    const res = await getUserService(id)

    return res
  } catch (error) {
    // return thunkApi.rejectWithValue(error[0].description)
    return thunkApi.rejectWithValue(error.description)
  }
})


export const operatorDeleteUser = createAsyncThunk('users/deleteUser', async (data, thunkApi) => {
  try {
    const res = await deleteUserService(data)

    return res
  } catch (error) {
    // return thunkApi.rejectWithValue(error[0].description)
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateUser = createAsyncThunk('users/createUser', async ({data, navigate}, thunkApi) => {
  try {
    const res = await createUserService(data)

    navigate && navigate(ROUTE_PATHS.users)

    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateUser = createAsyncThunk('users/updateUser', async ({id, data, navigate}, thunkApi) => {
  try {
    const res = await updateUserService({id, data})

    navigate && navigate(ROUTE_PATHS.users)

    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})
