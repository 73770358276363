import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addAttachmentService,
  changeSubmissionStatusService,
  createSubmissionService,
  deleteAttachmentService,
  deleteSubmissionService,
  getMySubmissionService,
  getSubmissionService,
  getSubmissionsService,
  updateSubmissionService,
} from "network/services/submission.service";
import { ROUTE_PATHS } from "utils/constants";

export const operatorListSubmissions = createAsyncThunk(
  "submission/getAll",
  async (payload, thunkApi) => {
    try {
      const { email, name, country } = payload.configs.params;
      if (!name || name.length === 0) delete payload.configs.params.name;
      if (!email || email.length === 0) delete payload.configs.params.email;
      if (!country || country.length === 0 || country === "none")
        delete payload.configs.params.country;
      // console.log(payload,"payload");
      const res = await getSubmissionsService(payload);
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error[0].description)
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorDeleteSubmission = createAsyncThunk(
  "submission/delete",
  async (payload, thunkApi) => {
    try {
      const res = await deleteSubmissionService(payload);
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error[0].description)
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorGetSubmission = createAsyncThunk(
  "submission/getSubmission",
  async (payload, thunkApi) => {
    try {
      const res = await getSubmissionService(payload);
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error[0].description)
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorCreateSubmission = createAsyncThunk(
  "submission/create",
  async (payload, thunkApi) => {
    try {
      const res = await createSubmissionService(payload);
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error[0].description)
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorUpdateSubmission = createAsyncThunk(
  "submission/update",
  async ({ navigate, ...payload }, thunkApi) => {
    try {
      const res = await updateSubmissionService(payload);
      navigate(ROUTE_PATHS.participation);
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error[0].description)
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorGetMySubmission = createAsyncThunk(
  "submission/getMine",
  async (payload, thunkApi) => {
    try {
      const res = await getMySubmissionService(payload);
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error[0].description)
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorDeleteAttachment = createAsyncThunk(
  "submission/deleteAttachment",
  async (payload, thunkApi) => {
    try {
      const res = await deleteAttachmentService(payload);
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error[0].description)
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorAddAttachment = createAsyncThunk(
  "submission/addAttachment",
  async (payload, thunkApi) => {
    try {
      const res = await addAttachmentService(payload);
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error[0].description)
      return thunkApi.rejectWithValue(error.description);
    }
  }
);

export const operatorChangeSubmissionStatus = createAsyncThunk(
  "submission/acceptSubmission",
  async (payload, thunkApi) => {
    try {
      const res = await changeSubmissionStatusService(payload);
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error[0].description)
      return thunkApi.rejectWithValue(error.description);
    }
  }
);
