
import { alpha, Box, Button } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "components/ui-kit/Logo";
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import {  ROUTE_PATHS, SIDEBAR } from "utils/constants";
import { AiOutlineLogout, AiOutlineHome } from 'react-icons/ai';
import { operatorLogout } from "redux-thunk/thunk/Auth/Auth";
import { dashboardRoutes } from "routes/routerList";
import { useTranslation } from "react-i18next";


const Sidebar = ({ userRole, isSideNavOpen, logoutHandler,activeCompetitionId,activeUserSubmissionId }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <Box sx={{
            transition: "all 0.3s ease-in-out",
            padding: 0,
            width: isSideNavOpen ? SIDEBAR.width : "0px",
            minWidth: isSideNavOpen ? SIDEBAR.width : "0px",
            maxWidth: isSideNavOpen ? SIDEBAR.width : "0px",
            backgroundColor: theme => alpha(theme.palette.common.black, 0.8),
            height: '100vh',
            overflowX: 'hidden',
            display: 'flex',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: theme => theme.spacing(1),
                height: '100%',
                minWidth: SIDEBAR.width,
            }}
            >
                <Box sx={{ my: 2, textAlign: 'center' }}>
                  <Link
                    to="/">
                    <Logo />
                  </Link>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        {
                            dashboardRoutes.map(({ allowedRoles, showInSideBar, icon, label, path }, index) => {
                                if (!showInSideBar) return null;
                                if (allowedRoles.indexOf(userRole) === -1) return null;

                                const isActive = location.pathname === path;
                                let pathToRoute = label==='messages'?
                                `${ROUTE_PATHS.competitions}/${activeCompetitionId}/submission/${activeUserSubmissionId}/messages`
                                :path
                                return (
                                    <Link key={index} to={pathToRoute}>
                                        <Button
                                            sx={{ justifyContent: 'flex-start', mb: 1 }}
                                            variant={isActive ? 'contained' : 'text'}
                                            color="white"
                                            fullWidth
                                            startIcon={icon}
                                        >
                                            {t(label)}
                                        </Button>
                                    </Link>
                                )
                            })
                        }
                    </Box>


                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: (theme) => theme.spacing(1),
                        }}
                    >

                        <Link to="/">
                            <Button
                                color="white"
                                fullWidth
                                startIcon={<AiOutlineHome />}
                            >
                                الرئيسية
                            </Button >
                        </Link>

                        <Button
                            color="white"
                            onClick={() => logoutHandler({ navigate })}
                            startIcon={<AiOutlineLogout />}
                        >
                            تسجيل الخروج
                        </Button >
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

Sidebar.propTypes = {
    isSideNavOpen: PropTypes.bool,
}

const mapStateToProps = (state) => {
    return {
        userRole: state.auth.role,
        isSideNavOpen: state.ham.openHam,
        activeCompetitionId: state.competition?.activeCompetition?.id,
        activeUserSubmissionId: state.submission?.mySubmission?.id,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutHandler: (data) => dispatch(operatorLogout(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
