import i18n from 'i18next'
import store from 'index'
import { handleLogout } from 'redux-thunk/redux/Auth/authSlice'
import { startLoader, stopLoader } from 'redux-thunk/redux/Loader/loaderSlice'
// import { startLoader, stopLoader } from 'redux-thunk/redux/Loader/loaderSlice'
import { getAuthToken, signOut } from 'utils/common-services/cookie.services'
import { openErrorToaster, openSuccessToaster } from 'utils/common-services/toaster.services'
import { ErrorCode } from 'utils/https'

export const requestHandler = (request) => {
  if (request.handlerEnabled) {
    const authToken = getAuthToken()
    if (authToken) {
      request.headers.Authorization = `Bearer ${authToken}`
    }
  }
  // Loader Logic to add loader
  if (request.loader) {
    store.dispatch(startLoader(request.loader))
  }
  return request
}

export const responseSuccessHandler = (response) => {
  // eslint-disable-next-line no-unused-vars
  const { responseType = 'json', loader, successMessage } = response.config || {}
  if (responseType === 'blob') {
    return { file: response.data, headers: response.headers }
  }
  // Loader logic to remove loader
  if (loader) {
    store.dispatch(stopLoader(loader))
  }
  // Open Success Toaster
  successMessage && openSuccessToaster(store.dispatch, successMessage || response.data.data.message)
  return response.data
}

export const errorHandler = (error) => {
  //* hide the loader
  store.dispatch(stopLoader(error.response.config.loader))

  if (error.response.status === 401) {
    // signOut Logic
    //remove from cookies
    signOut()

    // remove from redux
    store.dispatch(handleLogout())
    // openErrorToaster(store.dispatch, 'حدث خطأ في السيرفر، حاول لاحقاً')
  }
  else if (error.response.status === 404) {
  }
  else {
    // show error in response based on error code
    openErrorToaster(store.dispatch, i18n.t(`errors:${ErrorCode[error.response.data.errorCode]}`))
  }



  // else if (error.response.status === 400) {
  //   openErrorToaster(store.dispatch, error.response.data.errorCode?.data[0]?.errors[0]?.message)
  //   return Promise.reject(error.response.data)
  // }
  // else if (error.response.status >= 500) {
  //   // Snackbar Internal Server Error
  //   openErrorToaster(store.dispatch, 'حدث خطأ في السيرفر، حاول لاحقاً')
  //   return Promise.reject(error.response.data.errors)
  // }
  // else {
  //   openErrorToaster(store.dispatch, 'عفواً، حدث خطأ ما، برجاء المحاولة لاحقاً')
  //   return Promise.reject(error.response.error)
  // }
  // Other errors
  // if (error.response.config.loader) {
  //   store.dispatch(stopLoader(error.response.config.loader))
  // }
  // Open Error Toaster
  // openErrorToaster(store.dispatch, error.response.data.errors[0].description)
  return Promise.reject(error.response.error)
}
