import AppRoutes from "routes";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import AppLoader from "components/ui-kit/AppLoader";
import AppToaster from "components/ui-kit/AppToaster";
import { LOADER_TYPE } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  loaderDiv: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    inset: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 99999,
  },
  bodyLoadingContainer: {
    "-webkit-filter": "blur(5px)",
    "-moz-filter": "blur(5px)",
    "-ms-filter": "blur(5px)",
    "-o-filter": "blur(5px)",
    filter: "blur(5px)",
  },
}));

const App = ({ pageLoading }) => {
  const classes = useStyles();

  return (
    <>
      {pageLoading && (
        <div className={classes.loaderDiv}>
          <AppLoader variant={LOADER_TYPE.SCALE} />
        </div>
      )}
      <div className={pageLoading ? classes.bodyLoadingContainer : ""}>
        <AppToaster />
        <AppRoutes />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pageLoading: state.loader.pageLoader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
