import { createAsyncThunk } from '@reduxjs/toolkit'
import { changeCompetitionStatusService, createCompetitionService, deleteCompetitionService, getActiveCompetitionService, getArchivedCompetitionsService, getCompetitionService, getCompetitionsService, getCompetitionStatsService, getCompetitionSubmissionSheetService, toggleCompetitionActivationService, updateCompetitionService } from 'network/services/competition.service'
import { ROUTE_PATHS } from 'utils/constants'


export const operatorGetAllCompetitions = createAsyncThunk('competition/getAll', async (data, thunkApi) => {
  try {
    return await getCompetitionsService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorGetArchivedCompetitions = createAsyncThunk('competition/getArchived', async (data, thunkApi) => {
  try {
    return await getArchivedCompetitionsService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorGetCompetition = createAsyncThunk('competition/getCompetition', async (data, thunkApi) => {
  try {
    return await getCompetitionService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorGetActiveCompetition = createAsyncThunk('competition/getActiveCompetition', async (data, thunkApi) => {
  try {
    return await getActiveCompetitionService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateCompetition = createAsyncThunk('competition/createCompetition', async ({ navigate, data }, thunkApi) => {
  try {
    const res = await createCompetitionService(data)
    navigate(ROUTE_PATHS.competitions)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateCompetition = createAsyncThunk('competition/updateCompetition', async ({ navigate, data }, thunkApi) => {
  try {
    const res = await updateCompetitionService(data)
    navigate(ROUTE_PATHS.competitions)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})


export const operatorChangeCompetitionStatusCompetition = createAsyncThunk('competition/changeCompetitionStatusCompetition', async (data, thunkApi) => {
  try {
    const res = await changeCompetitionStatusService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})


export const operatorDeleteCompetition = createAsyncThunk('competition/delete', async (data, thunkApi) => {
  try {
    const res = await deleteCompetitionService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})


export const operatorToggleActiveCompetition = createAsyncThunk('competition/toggleActivation', async (data, thunkApi) => {
  try {
    const res = await toggleCompetitionActivationService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})


export const operatorGetCompetitionStatsCompetition = createAsyncThunk('competition/getCompetitionStatsCompetition', async (data, thunkApi) => {
  try {
    const res = await getCompetitionStatsService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})


export const operatorGetCompetitionSubmissionSheet = createAsyncThunk('competition/getCompetitionSubmissionSheet', async (data, thunkApi) => {
  try {
    const res = await getCompetitionSubmissionSheetService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})
