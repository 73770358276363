import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ userLoaded, userRole, isAuthenticated, Component, allowedRoles, ...rest }) => {
  if (!isAuthenticated && userLoaded && userRole && !allowedRoles?.includes(userRole)) {
    return <Navigate to='/' />
  }

  return <Component />
}

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.role,
    userLoaded: state.auth.userLoaded,
    isAuthenticated: state.auth.token,
  }
}


export default connect(mapStateToProps)(PrivateRoute)
