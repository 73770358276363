import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { ROUTE_PATHS } from 'utils/constants/index'

const PublicRoute = ({ Component, isAuthenticated, visitorsOnly }) => {
  const { pathname } = useLocation()

  return (isAuthenticated && visitorsOnly && (pathname !== ROUTE_PATHS.rootPath)) ? <Navigate to={ROUTE_PATHS.rootPath} /> : <Component />
}

export default PublicRoute
