import { createAsyncThunk } from '@reduxjs/toolkit'
import { createSponsorService, deleteSponsorService, getSponsorService, listSponsorsService, updateSponsorService } from 'network/services/sponsor.service'
import { ROUTE_PATHS } from 'utils/constants'


export const operatorListSponsors = createAsyncThunk('sponsor/list', async (data, thunkApi) => {
  try {
    return await listSponsorsService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorGetSponsor = createAsyncThunk('sponsor/get', async (data, thunkApi) => {
  try {
    return await getSponsorService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateSponsor = createAsyncThunk('sponsor/create', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await createSponsorService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.sponsorParentRoute}`)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateSponsor = createAsyncThunk('sponsor/update', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await updateSponsorService(payload)
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${payload.competitionId}/${ROUTE_PATHS.sponsorParentRoute}`)

    return res

  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorDeleteSponsor = createAsyncThunk('sponsor/delete', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await deleteSponsorService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})
