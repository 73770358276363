import { createAsyncThunk } from '@reduxjs/toolkit'
import { createCommentService, createMessageService, deleteCommentService, deleteMessageService, getMessageService, listMessagesService, updateCommentService, updateMessageService } from 'network/services/adminMessages.service'
import { ROUTE_PATHS } from 'utils/constants'


export const operatorListMessages = createAsyncThunk('message/list', async (data, thunkApi) => {
  try {
    return await listMessagesService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorGetMessage = createAsyncThunk('message/get', async (data, thunkApi) => {
  try {
    return await getMessageService(data)
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateMessage = createAsyncThunk('message/create', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await createMessageService(payload)
    const {competitionId,submissionId} = payload
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${competitionId}/${ROUTE_PATHS.submissionParentRoute}/${submissionId}/messages`)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateMessage = createAsyncThunk('message/update', async ({ navigate, ...payload }, thunkApi) => {
  try {
    const res = await updateMessageService(payload)
    const {competitionId,submissionId} = payload
    navigate(`${ROUTE_PATHS.dashboard}/${ROUTE_PATHS.competitionParentRoute}/${competitionId}/${ROUTE_PATHS.submissionParentRoute}/${submissionId}/messages`)
    return res

  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorDeleteMessage = createAsyncThunk('message/delete', async (payload, thunkApi) => {
  try {
    const res = await deleteMessageService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorCreateComment = createAsyncThunk('message/createComment', async (payload, thunkApi) => {
  try {
    const res = await createCommentService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorUpdateComment = createAsyncThunk('message/updateComment', async (payload, thunkApi) => {
  try {
    const res = await updateCommentService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})

export const operatorDeleteComment = createAsyncThunk('message/deleteComment', async (payload, thunkApi) => {
  try {
    const res = await deleteCommentService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error.description)
  }
})
