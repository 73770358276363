import { createSlice } from '@reduxjs/toolkit'
import { operatorCreateSponsor, operatorDeleteSponsor, operatorGetSponsor, operatorListSponsors, operatorUpdateSponsor } from 'redux-thunk/thunk/Sponsor/Sponsor'

const defaultState = {}

const sponsorSlice = createSlice({
  name: 'sponsorSlice',
  initialState: defaultState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(operatorListSponsors.fulfilled, (state, action) => {
        return {
          ...defaultState,
          sponsors: action.payload
        }
      })
      .addCase(operatorGetSponsor.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorCreateSponsor.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorUpdateSponsor.fulfilled, (state, action) => {
        return {
          ...defaultState,
        }
      })
      .addCase(operatorDeleteSponsor.fulfilled, (state, action) => {
        state.sponsors = state.sponsors.filter((doc) => doc.id !== action.meta.arg.sponsorId)
      })
  }
})

export default sponsorSlice.reducer
