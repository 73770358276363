import DashboardLayout from 'components/layout/DashboardLayout'
import EmptyLayout from 'components/layout/EmptyLayout'
import MainLayout from 'components/layout/MainLayout'
import React, { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { authRoutes, dashboardRoutes, routerList } from 'routes/routerList'
import RouteValidator from './RouteValidator'

const NotFound = lazy(() => import('components/layout/NotFound'))


const CustomRoutes = () => (
  <>
    <Routes>

      {/* LAYOUT WITH HEADER AND FOOTER */}
      <Route element={<MainLayout />}>
        {routerList.map(({ path, key, ...props }) => {
          return (
            <Route
              path={path}
              key={key}
              exact
              element={<RouteValidator path={path} {...props} />}
            />
          )
        })}
      </Route>


      {/* LAYOUT WITH NOOO HEADER AND FOOTER */}
      <Route element={<EmptyLayout />}>
        {authRoutes.map(({ path, key, ...props }) => {
          return (
            <Route
              path={path}
              key={key}
              exact
              element={<RouteValidator path={path} {...props} />}
            />
          )
        })}
      </Route>




      {/* DASHBOARD LAYOUT */}
      <Route element={<DashboardLayout />}>
        {dashboardRoutes.map(({ path, key, ...props }) => {
          return (
            <Route
              path={path}
              key={key}
              exact
              element={<RouteValidator path={path} {...props} />}
            />
          )
        })}
      </Route>



      <Route
        path='*'
        element={<NotFound />}
      />
    </Routes>
  </>
)

export default CustomRoutes
