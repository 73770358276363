import React from 'react';
import ReactDOM from 'react-dom'
import App from './App';
import reportWebVitals from './reportWebVitals'
import 'assets/translation'
import { appRoot } from 'assets/dom/domNodes'
import { store } from 'redux-thunk/store'
import { Provider } from 'react-redux'


import './index.scss'
import ProviderWrappers from 'utils/wrappers/ProviderWrappers';


ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ProviderWrappers>
      <App />
    </ProviderWrappers>
  </Provider>
  //  </React.StrictMode > 
  ,
  appRoot
)


export default store

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
