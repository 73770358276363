import axiosInstance, { microServices } from 'network/apis'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'

const GET_ALL = 'v1/users'
const TOGGLE_ACTIVE_USER = 'v1/users'
const CREATE_NEW = 'v1/users'
const DELETE = 'v1/users'
const GET_USER = 'v1/users'
const UPDATE_USER = 'v1/users'

/**
 * Service to get all users
 * @param {object} data - object contains username and password
 */
export const getUsersService = (data) => {
  return axiosInstance(
    METHOD_TYPES.get,
    GET_ALL,
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.data
    }
  )
}

/**
 * Service to create new user
 * @param {object} data - object contains username and password
 */
export const createUserService = (data) => {
  return axiosInstance(
    METHOD_TYPES.post,
    CREATE_NEW,
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم إضافة المستخدم بنجاح'
    }
  )
}

/**
 * Service to delete new user
 * @param {object} id - id
 */
export const deleteUserService = (id) => {
  return axiosInstance(
    METHOD_TYPES.delete,
    `${DELETE}/${id}`,
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم حذف المستخدم بنجاح'
    }
  )
}


export const toggleActiveUserService = (id) => {
  return axiosInstance(
    METHOD_TYPES.patch,
    `${TOGGLE_ACTIVE_USER}/${id}`,
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تمت العملية بنجاح'
    }
  )
}


export const getUserService = (id) => {
  return axiosInstance(
    METHOD_TYPES.get,
    `${GET_USER}/${id}`,
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page
    }
  )
}

export const updateUserService = ({ id, data }) => {
  return axiosInstance(
    METHOD_TYPES.put,
    `${UPDATE_USER}/${id}`,
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم تعديل المستخدم بنجاح'
    }
  )
}
