import { createTheme, responsiveFontSizes } from '@mui/material'
import { colors } from './colors'

const theme = createTheme({
  shape: {
    borderRadius: 4
  },
  components: {
    MuiButton: {
      defaultProps: {
        // size: "small",
        disableElevation: true,
      }
    },
    MuiFormControl: {
      defaultProps: {
        // size: "small",
      }
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiButton-containedWhite': {
            color: colors.dark,
          },
          '&.MuiButton-containedDark': {
            color: colors.white,
          },
          '& .MuiTouchRipple-rippleVisible': {
            animationDuration: `250ms!important`,
          },
          '&.MuiButton-sizeSmall .MuiTouchRipple-rippleVisible': {
            animationDuration: `150ms!important`,
          },
          '&.MuiButton-sizeLarge .MuiTouchRipple-rippleVisible': {
            animationDuration: `350ms!important`,
          },
        },
      }
    },

    MuiMenu: {
      root: {
        paddingTop: "0",
        paddingBottom: "0"
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            lineHeight: 1,
          },

          '&.white': {
            '& .MuiInputBase-input': {
              color: colors.white,
            },
            "& label": {
              color: colors.white
            },
            "& label.Mui-focused": {
              color: colors.white
            },
            "& .MuiInput-underline": {
              '&:after': {
                borderBottomColor: colors.white
              }
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: colors.white
              },
              "&.Mui-focused fieldset": {
                borderWidth: 2,
                borderColor: colors.white
              }
            },
            '& .Mui-error': {
              color: colors.white
            }
          }
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#C29128',
    },
    secondary: {
      main: '#00A651',
    },
    white: {
      main: colors.white
    },
    dark: {
      main: colors.dark
    }
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: ['Tajawal', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightBold: 700,
    lineHeight: 1.5,
  },
  drawer: {
    width: 240
  },
  colors: colors,
  drawerWidth: 300,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1600,
    },
  },
})


export default responsiveFontSizes(theme);
