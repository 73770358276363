

export const ErrorCode = [
  "BadRequestError",
  "UnauthorizedError",
  "PaymentRequiredError",
  "ForbiddenError",
  "NotFoundError",
  "ConflictError",
  "UnprocessableEntityError",
  "InternalServerError",
  "BadGatewayError",
  "ValidationError",
  "EmailIsAlreadyTakenError",
  "UserDoesNotExistError",
  "InvalidToken",
  "IncorrectPassword",
  "UserIsNotActive",
  "CompetitionDoesNotExist",
  "CannotUpdateActiveCompetition",
  "InsufficientPermissions",
  "CannotDeactivateRunningCompetition",
  "CannotUpdateCompetitionToPreviousStatus",
  "CannotHaveMoreThanOneActiveCompetition",
  "CannotDeleteActiveCompetition",
  "FileDoesNotExist",
  "EntityDoesNotExist",
  "GroupDoesNotExist",
  "CategoryDoesNotExist",
  "StageDoesNotExist",
  "SponsorDoesNotExist",
  "ResultDoesNotExist",
  "EventDoesNotExist",
  "InquiryDoesNotExist",
  "CannotCreateInquiryOnInactiveOrArchivedCompetition",
  "CommentDoesNotExist",
  "NoActiveCompetitionExists",
  "SubmissionDoesNotExist",
  "CannotUpdateSubmission",
  "InvalidAttachment",
  "MissingSponsorLogo",
  "MissingAttachmentFile",
  "CannotCreateMoreThanOneSubmission",
  "CannotDeleteReviewedSubmission",
  "CannotDeleteCategoryWithSubmissions",
  "CannotActivateIncompleteCompetition",
  "CompetitionIsInactiveOrPostReception",
  "TooManyUploadedFiles",
  "UploadError",
  "EmployeeRegistrationDisallowed",
  "ChangingPasswordDisallowed",
  "ChangingEmailDisallowed",
  "EmployeeLoginDisallowed",
  "CompetitionIsNotActive",
  "CompetitionIsArchived",
  "ForbiddenSubmissionStatus",
  "CannotCreateMessageOnInactiveOrArchivedCompetition",
  "MessageDoesNotExist",
  "MissingDocument"
]
