import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enCommon from './locales/en/common.json'
import arCommon from './locales/ar/common.json'

import enErrors from './locales/en/errors.json'
import arErrors from './locales/ar/errors.json'


import { LANGUAGES } from 'utils/constants'
import { getLocalLanguage } from 'utils/common-services/localstorage.services'

i18n.use(initReactI18next).init({
  resources: {
    ar: {
      common: arCommon,
      errors: arErrors
    },
    en: {
      common: enCommon,
      errors: enErrors
    },
  }, // languages available
  lng: getLocalLanguage() || LANGUAGES[0].languageCode, 
  fallbackLng: LANGUAGES[0].languageCode,
  ns: 'common|errors', //
  defaultNS: 'common',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
})

export default i18n
