import React, { useEffect } from 'react'

import { CssBaseline } from '@mui/material'
import theme from 'theme/theme'
import { ThemeProvider } from '@mui/material'

import { StyledEngineProvider } from "@mui/material";

// RTL stuff
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { connect } from 'react-redux';
import { LANGUAGES } from 'utils/constants';




const ProviderWrappers = ({ languageCode, children }) => {
    const [currentLanguage, setCurrentLanguage] = React.useState(languageCode);

    // Create rtl cache
    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
        prepend: true,
    });

    function RTL(props) {
        return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
    }

    useEffect(() => {
        setCurrentLanguage(LANGUAGES.find(language => language.languageCode === languageCode));

        document.documentElement.setAttribute("lang", currentLanguage.languageCode);
        // Set RTL
        document.documentElement.setAttribute("dir", currentLanguage.isRTL ? 'rtl' : 'ltr');
    }, [currentLanguage.isRTL, currentLanguage.languageCode, languageCode]);



    return (
        languageCode ? <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {
                        currentLanguage.isRTL ? <RTL>{children}</RTL> : children
                    }
                </ThemeProvider>
            </StyledEngineProvider>
        </> : <></>
    )
}




const mapStateToProps = (state) => {
    return {
        languageCode: state.i18n.language
    }
}

export default connect(mapStateToProps)(ProviderWrappers)
