import axiosInstance, { microServices } from 'network/apis'
import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'

const LIST = (competitionId) => `/v1/competitions/${competitionId}/groups`
const GET_ONE = (competitionId, groupId) => `/v1/competitions/${competitionId}/groups/${groupId}`
const CREATE = (competitionId) => `/v1/competitions/${competitionId}/groups`
const UPDATE = (competitionId, groupId) => `/v1/competitions/${competitionId}/groups/${groupId}`
const DELETE = (competitionId, groupId) => `/v1/competitions/${competitionId}/groups/${groupId}`


export const listGroupsService = (payload) => {
  const { competitionId, data } = payload
  return axiosInstance(
    METHOD_TYPES.get,
    LIST(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
    }
  )
}

export const getGroupService = (payload) => {
  const { competitionId, groupId } = payload

  return axiosInstance(
    METHOD_TYPES.get,
    GET_ONE(competitionId, groupId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.submit,
    }
  )
}

export const createGroupService = (payload) => {
  const { competitionId, data } = payload

  return axiosInstance(
    METHOD_TYPES.post,
    CREATE(competitionId),
    data,
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم الإضافة بنجاح'
    }
  )
}

export const updateGroupService = (payload) => {
  const { competitionId, groupId, data } = payload

  return axiosInstance(
    METHOD_TYPES.put,
    UPDATE(competitionId, groupId),
    { ...data },
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم التعديل بنجاح'
    }
  )
}
export const deleteGroupService = (payload) => {
  const { competitionId, groupId } = payload

  return axiosInstance(
    METHOD_TYPES.delete,
    DELETE(competitionId, groupId),
    {},
    {
      server: microServices.BASE_URL1,
      handlerEnabled: true,
      loader: LOADER_HANDLER_TYPES.page,
      successMessage: 'تم الحذف بنجاح'
    }
  )
}
